import {
  KPIS,
  PATTERN_TYPE,
  SEVERITY,
} from "../constants/multiSelectFilterConstants";
import { isEmpty } from "./is";

/**
 * Below method takes in key value pairs of query params and converts it into 2 dimensional array of query params to be consumed by
 * URLSearchParams()
 * pipelineSchedule is an optional parameter which is not required by all the API calls made on L3 page
 */
export const getQueryParamsFromFilterObject = (
  filterSelector,
  tenantId,
  pipelineSchedule,
  dimensionId,
  dimensionName,
  dimensionValue,
  dimensionsString = null,
  isForecastEnabled,
  subType = null,
  event_details_message = null,
  isPixel = false,
  isDemoMode = false,
) => {
  const selectedFilter = [];
  Object.keys(filterSelector).map((key) => {
    // extract the filter keys (that needs to be hashed)
    if (!["isForecastEnabled", "pipelineSchedule", "chartType", "selectedDimensionValues", "selectedDimensionNames"].includes(key)) {
      const selectedKeys = [];
      if (filterSelector[key]?.length) {
        const filterString = filterSelector[key]
          .map((filter) => {
            if (key === KPIS) {
              return filter.kpi_id;
            } else if (key === PATTERN_TYPE) {
              return filter.pattern_type;
            } else if (key === SEVERITY) {
              return filter.severity_type;
            }
          })
          // join function will concat the string arrays with comma, as required by the API spec
          .join();

        // push filter_key
        selectedKeys.push(key.toLowerCase());
        // push filter_value
        selectedKeys.push(filterString);

        // push the above array to the main filter array
        selectedFilter.push(selectedKeys);
      }
    }
    if (key === "timeStamps") {
      selectedFilter.push(["start_time", filterSelector[key]?.startTime]);
      selectedFilter.push(["end_time", filterSelector[key]?.endTime]);
    }
  });
  // Add other filter keys, that should not be hashed
  selectedFilter.push(["tenant_id", tenantId]);

  // Add `pipelineSchedule` if present in the params
  if (pipelineSchedule) {
    selectedFilter.push(["pipeline_schedule", pipelineSchedule]);
  }

  // If dimension_id is present push it
  if(dimensionId){
    selectedFilter.push(["dimension_id", dimensionId]);
  }

  // We need to explicitly allow null values to be a part of Query params
  if (dimensionName !== undefined) {
    selectedFilter.push(["dim_name", dimensionName]);
  }

  // We need to explicitly allow null values to be a part of Query params
  if (dimensionValue !== undefined) {
    selectedFilter.push(["dim_val", dimensionValue]);
  }

  // put in dimensions String
  if(dimensionsString !== undefined && dimensionsString !== null){
    selectedFilter.push(["dimensions", dimensionsString]);
  }

  // Put is_forecast_enabled if present
  if(isForecastEnabled !== undefined && isForecastEnabled !== null){
    selectedFilter.push(["is_forecast_enabled", isForecastEnabled]);
  }
  if (subType) {
    selectedFilter.push(["sub_type", subType]);
  }
  if(event_details_message){
    selectedFilter.push(["event_details_message", event_details_message]);
  }
  if (isPixel) {
    selectedFilter.push(["is_pixel", true]);
  }
  if (isDemoMode) {
    selectedFilter.push(["is_demo", true]);
  }

  return selectedFilter;
};

export const generateDimensionListQueryParam = (dimensionNamesArray, dimensionValuesArray = []) => {
  let dimensionArrayString = "";
  if (dimensionValuesArray?.length && dimensionNamesArray?.length) {
    dimensionValuesArray.forEach(element => {
      if(isEmpty(dimensionArrayString)){
        dimensionArrayString += `${dimensionNamesArray[0].dimension_name}:${element}`;
      }else{
        dimensionArrayString += `,${dimensionNamesArray[0].dimension_name}:${element}`;
      }
    });
  }
  return isEmpty(dimensionArrayString) ? null : dimensionArrayString ;
};