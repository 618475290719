import { Box } from "@mui/material";
import { formatISO } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, LoaderInCenter } from "../components/common/styled-components";
import ReportsHeader from "../components/Reports/ReportsHeader";
import ReportsList from "../components/Reports/ReportsList";
import { REPORTS_FEED_UPDATE_TYPE } from "../constants/reportsConstants";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { isEmpty } from "../utils/is";
import { useSearchParams } from "react-router-dom";

const EventGroupScreen = () => {
  const dispatch = useDispatch();
  const selectedTenant = useSelectedTenant();
  const isDemoMode = useSelector((state) => state.demoModeFilter?.isDemoMode);
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
    const defaultStartDate = useSelector((state) => state.reportsFeedFilters.timestamps?.startDate);
    const defaultEndDate = useSelector((state) => state.reportsFeedFilters.timestamps?.endDate);
  const [searchParams, setSearchParams] = useSearchParams();

  const reportsFeedFilter = useSelector((state) => state.reportsFeedFilters);
  const [providerTypes, setProviderTypes] = useState([]);
  const [providersData, setProvidersData] = useState([]);
  const [providerTypeDisplayName, setProviderTypeDisplayName] = useState([]);

  const params = {
    tenant_id: selectedTenant,
    dim_name: tenantGlobalFilter?.dimension_name,
    dim_val: tenantGlobalFilter?.dimension_value,
    start_time: formatISO(defaultStartDate, { representation: "date" }),
    end_time: formatISO(defaultEndDate, { representation: "date" }),
    ...(isDemoMode && { is_demo: true }),
  };

  const {
    data: reportsData,
    status: status,
    isLoading: fetchingReports,
  } = useAxios("events/reports", {
    params: params,
  });

    useEffect(() => {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("startDate", formatISO(defaultStartDate, { representation: "date" }));
      updatedParams.set("endDate", formatISO(defaultEndDate, { representation: "date" }));
      setSearchParams(updatedParams);
    }, [defaultStartDate, defaultEndDate]);

  useEffect(() => {
    if (!isEmpty(reportsData)) {
      let providers = [];
      let providerTypeName = "";
      const graphanaDashboardTab = {
        value: "dashboard",
        label: "Errors",
        description: "Summary of website errors, including JavaScript, Network and Fetch errors",
      };
      const filteredProviderTypes = reportsData.data?.provider_types.map((item) => ({
        value: item?.provider_type_name,
        label: item?.provider_type_display_name,
        description: item?.description,
      }));
      if (reportsFeedFilter?.type) {
        const selectedTypeDetails = reportsData.data?.provider_types.find(
          (item) => item.provider_type_name === reportsFeedFilter?.type
        );
        if (reportsFeedFilter.type !== "dashboard") {
          providers = selectedTypeDetails.providers;
          providerTypeName = selectedTypeDetails.provider_type_display_name;
        }
      } else {
        providers = reportsData.data?.provider_types[0].providers;
        providerTypeName = reportsData.data?.provider_types[0].provider_type_display_name;
        dispatch({
          type: REPORTS_FEED_UPDATE_TYPE,
          payload: filteredProviderTypes[0].value,
        });
      }
      setProviderTypes([...filteredProviderTypes]);
      setProvidersData(providers);
      setProviderTypeDisplayName(providerTypeName);
    }
  }, [reportsData, dispatch, reportsFeedFilter]);

  if (fetchingReports) {
    return <LoaderInCenter />;
  }

  const handleProviderTypeUpdate = (updatedType) => {
    if (updatedType !== "dashboard") {
      const selectedTypeDetails = reportsData.data?.provider_types.find(
        (item) => item.provider_type_name === updatedType
      );
      if (selectedTypeDetails) {
        setProvidersData(selectedTypeDetails.providers);
        setProviderTypeDisplayName(selectedTypeDetails.provider_type_display_name);
      }
    }
  };

  const jsError = `https://charts.ops.outoftheblue.ai/d-solo/d495f743-a17b-4e08-8d1e-670212330e44/prod-error-generation?orgId=1&from=1741335328820&to=1741680928820&timezone=utc&var-client=${selectedTenant}&var-store=${encodeURIComponent(tenantGlobalFilter?.dimension_value)}&var-js_error_type=unhandledrejection&var-network_error_type=asset_error&var-fetch_error_type=internal_server_error&theme=light&panelId=7&__feature.dashboardSceneSolo`;
  const fetchError = `https://charts.ops.outoftheblue.ai/d-solo/d495f743-a17b-4e08-8d1e-670212330e44/prod-error-generation?orgId=1&from=1741335328820&to=1741680928820&timezone=utc&var-client=${selectedTenant}&var-store=${encodeURIComponent(tenantGlobalFilter?.dimension_value)}&var-js_error_type=unhandledrejection&var-network_error_type=asset_error&var-fetch_error_type=internal_server_error&theme=light&panelId=6&__feature.dashboardSceneSolo`;
  const networkError = `https://charts.ops.outoftheblue.ai/d-solo/d495f743-a17b-4e08-8d1e-670212330e44/prod-error-generation?orgId=1&from=1741335328820&to=1741680928820&timezone=utc&var-client=${selectedTenant}&var-store=${encodeURIComponent(tenantGlobalFilter?.dimension_value)}&var-js_error_type=unhandledrejection&var-network_error_type=asset_error&var-fetch_error_type=internal_server_error&theme=light&panelId=5&__feature.dashboardSceneSolo`;

  return (
    <Container bgColor="var(--grey-50)" containerPadding="0px">
      <Box
        sx={(theme) => ({
          width: "100%",
          maxWidth: "100%",
          minWidth: "400px",
          padding: "0px",
          margin: "0 auto",
          marginTop: "0px",
          [theme.breakpoints.down("tm")]: {
            minWidth: "unset"
          },
        })}
      >
        <ReportsHeader
          providerTypes={providerTypes}
          handleProviderType={handleProviderTypeUpdate}
        />
        {reportsFeedFilter.type !== "dashboard" ? (
          <ReportsList providersData={providersData} providerTypeDisplayName={providerTypeDisplayName} />
        ) : (
          <Box
            sx={(theme) => ({
              padding: "48px 150px",
              paddingTop: "0px",
              marginTop: "250px",
              [theme.breakpoints.down("tm")]: {
                padding: "48px 50px",
                paddingTop: "0px",
                marginTop: "230px",
              },
              [theme.breakpoints.down("lt")]: {
                marginTop: "330px",
              },
            })}
          >
            <iframe src={jsError} width="100%" height="350" frameBorder="0"></iframe>
            <iframe src={fetchError} width="100%" height="350" frameBorder="0"></iframe>
            <iframe src={networkError} width="100%" height="350" frameBorder="0"></iframe>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default EventGroupScreen;
