import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Local Imports
import { MASTER_TENANT } from "../../../constants/tenantConstants";
import { Tooltip } from "@mui/material";
import { useLocalStorageState } from "../../../hooks";
import { UserAccountMenuItemStyles, UserAccountLastMenuItemStyles } from "../../common/styled-components/NavLink.styled";
import Icon from "../Icon";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { useSelector } from "react-redux";

const LOGOUT_URL = process.env.REACT_APP_BASE_URL + "logout";

const UserProfile = () => {
  const user = useAuthenticatedUser();
  const isDemoMode = useSelector((state) => state.demoModeFilter?.isDemoMode);
  const selectedTenant = useSelectedTenant();
  const [anchorEl, setAnchorEl] = useState(null);
  const [, , clearSelectedTenantFromLS] = useLocalStorageState("selectedTenant");
  const [, , clearAppliedGlobalFilter] = useLocalStorageState("currentAppliedGlobalFilters");

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  /**
   *  This function handles the click event on user icon. it will open the user account menu like a drop down
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *  This function handles the click event on user icon. It will close the user account menu like a drop down
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * When user clicks on logout button, It will logout the user and destroy the session
   */
  const logout = async () => {
    await axios
      .get(LOGOUT_URL)
      .then((data) => {
        clearSelectedTenantFromLS();
        clearAppliedGlobalFilter();
        // reset the Heap user Identity on logout
        window?.heap?.resetIdentity();
        navigate("/login");
      })
      .catch((err) => {
        // handle logout error navigation
      });
  };

  /**
   * When user clicks on Manage Datasources button, It will navigate the user to the datasources page
   * where user can manage the datasources
   */
  const showDatasources = () => {
    navigate("/datasources");
  };

  const showChangeCustomers = () => {
    navigate("/customers");
  };

  const showPreferences = () => {
    navigate("/preferences?tab=profile");
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings" placement="bottom-start">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              ml: 4,
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Icon name="Account settings" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        disableScrollLock
        sx={{
          top: "20px",
          borderRadius: "50px 20px",
        }}
        container={(document.body.style.paddingRight = 0)}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 3,
            "& .MuiMenu-list": {
              paddingBottom: "0px",
              paddingTop: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -1,
              mr: 2,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",

              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user?.tenant_id == MASTER_TENANT && (
          <Tooltip title="Change Customer" placement="left">
            <MenuItem onClick={showChangeCustomers} sx={UserAccountMenuItemStyles}>
              <Icon name="Account settings"></Icon>
              <span style={{ marginLeft: "9px", alignSelf: "baseline" }}>{selectedTenant ? selectedTenant : 'Change Customer'}</span>
            </MenuItem>
          </Tooltip>
        )}
        {!isDemoMode && (
        <MenuItem onClick={showPreferences} sx={{
          ...UserAccountMenuItemStyles,
          paddingLeft: "12px",
        }}>
          <Icon name="preferences-icon"></Icon>
          <span style={{ marginLeft: "10px", alignSelf: "baseline" }}>Preferences</span>
        </MenuItem>
        )}
        {!isDemoMode && (
        <MenuItem onClick={showDatasources} sx={{
          ...UserAccountMenuItemStyles,
          paddingLeft: "12px",
        }}>
          <Icon name="menu-onboarding"></Icon>
          <span style={{ marginLeft: "10px", alignSelf: "baseline" }}>Manage Datasources</span>
        </MenuItem>
        )}
        <MenuItem onClick={logout} sx={UserAccountLastMenuItemStyles}>
          <Icon name="Logout"></Icon>
          <span style={{ marginLeft: "10px", alignSelf: "baseline" }}>Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserProfile;
