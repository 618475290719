import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { formatISO } from "date-fns";
import { useSelector } from "react-redux";
import { eventHealthandColor, eventHealthandIcon } from "../../constants/commonConstants";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { typography } from "../../shared/theme-constants";

const ReportsList = ({ providersData = [], providerTypeDisplayName = null }) => {
  const reportsFeedFilter = useSelector((state) => state.reportsFeedFilters);
  const selectedTenant = useSelectedTenant();

  const handleRedirectToEvents = (provider_id, provider_check = null) => {
    // Create a URLSearchParams object
    const params = new URLSearchParams();

    // Add query parameters by encoding them.
    params.set("provider_uuid", encodeURIComponent(provider_id));
    params.set("provider_type", encodeURIComponent(reportsFeedFilter?.type));
    params.set("tenant_id", selectedTenant);
    params.set(
      "startDate",
      formatISO(reportsFeedFilter?.timestamps?.startDate, { representation: "date" })
    );
    params.set(
      "endDate",
      formatISO(reportsFeedFilter?.timestamps?.endDate, { representation: "date" })
    );

    // Conditionally add provider_check if it's not null
    if (provider_check) {
      params.set("provider_check", encodeURIComponent(provider_check));
    }
    // Dispatch to update events filters in Redux
    const filterData = {
      provider_uuid: provider_id,
      provider_type: reportsFeedFilter?.type,
      provider_check: provider_check || null,
    };
    // Construct the full URL
    const url = `/events?${params.toString()}`;
    // Open the URL in a new tab
    window?.location?.replace(url);
  };

  return (
    <Box
      sx={(theme) => ({
        padding: "48px 150px",
        paddingTop: "0px",
        marginTop: "250px",
        "& .masonry-container": {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridAutoFlow: "dense",
          columnGap: "24px",
          rowGap: "18px",
        },
        [theme.breakpoints.down("tm")]: {
          padding: "48px 50px",
          paddingTop: "0px",
          marginTop: "230px",
          "& .masonry-container": {
            gridTemplateColumns: "1fr",
            justifyContent: "center",
          },
        },
        [theme.breakpoints.down("sl")]: {
          "& .masonry-container": {
            gridTemplateColumns: "repeat(2, 1fr)",
            justifyContent: "center",
          },
        },
        [theme.breakpoints.down("lt")]: {
          marginTop: "330px",
          "& .masonry-container": {
            gridTemplateColumns: "1fr",
            justifyContent: "center",
          },
        },
      })}
    >
      {providerTypeDisplayName ? (
        <Box sx={{ paddingBottom: "16px" }}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(20),
              fontWeight: typography.font_weight.semiBold,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              color: "var(--charcoal-grey)",
              opacity: 0.87,
            })}
          >
            {`${providerTypeDisplayName} Events`}
          </Typography>
        </Box>
      ) : null}
      <Box className="masonry-container">
      {providersData.map((item, index) => {
        // Calculate the span based on the number of checks
        const itemSpan = item.checks?.statuses?.length ?
        Math.max(8, Math.min(30, 8 + (item.checks.statuses.length * 2))) : 8;
      return (
        <Accordion
          sx={(theme) => ({
            width: "100%",
            gridRowEnd: `span ${itemSpan}`,
            border: "0.5px solid #EBEBEB",
            boxShadow: "unset",
            borderRadius: "6px",
            height: "fit-content",
            [theme.breakpoints.down("sl")]: {
              width: "100%",
            },
            "&::before": {
              content: "none", // Removes the pseudo-element
            },
            "&.Mui-expanded": {
              margin: 0, // Set margin to 0 when Accordion is expanded
            },
          })}
          key={item.provider_uuid}
          defaultExpanded
          className="masonry-item"
        >
          <Box>
            <AccordionSummary
              expandIcon={null}
              sx={{
                borderBottom: "0.5px solid #EBEBEB",
                padding: "16px",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0, // Set margin to 0 for the expanded AccordionSummary content
                },
              }}
              onClick={() => handleRedirectToEvents(item.provider_uuid)}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack flexDirection="column" gap={3} alignItems="center">
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={2.5}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    <img
                      style={{ width: "20px", height: "20px", objectFit: "contain" }}
                      src={item.provider_logo_url}
                    />
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(16),
                        fontWeight: typography.font_weight.mediumx,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        color: "var(--black)",
                        opacity: 0.87,
                      })}
                    >
                      {item.provider_display_name}
                    </Typography>
                    <Tooltip
                      title={`List of checks that are being done for ${item.provider_display_name}`}
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "var(--black)",
                            backgroundColor: "#E6E6E6",
                            fontSize: "0.7em",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ width: "14px", height: "14px" }}
                        fontSize="small"
                        color="#00000099"
                      />
                    </Tooltip>
                  </Stack>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    {`${item.checks?.completed} of ${item.checks?.total} checks done`}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Box
                    sx={(theme) => ({
                      borderRadius: "100px",
                      backgroundColor: eventHealthandColor[item.health],
                      padding: "5px 10px",
                      width: "87px",
                      height: "28px",
                    })}
                  >
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={1.5}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${
                          eventHealthandIcon[item.health]
                        }`}
                        style={{ width: "14px", height: "14px", objectFit: "contain" }}
                      />
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(12),
                          fontWeight: typography.font_weight.regular,
                          color: "var(--grey-800)",
                        })}
                      >
                        {item.health_display_name}
                      </Typography>
                    </Stack>
                  </Box>
                  <ExpandMoreIcon
                    sx={{
                      transform: "rotate(270deg)",
                      color: "var(--dimmed-black)",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Stack>
              </Box>
            </AccordionSummary>
          </Box>
          <AccordionDetails
            sx={{
              padding: 0,
              "&:last-child": { paddingBottom: 0 },
            }}
          >
            {item.checks?.statuses.map((check, index) => (
              <Stack
                key={check.provider_check_uuid}
                flexDirection="row"
                alignItems="center"
                sx={{ width: "100%", borderBottom: "0.5px solid #EBEBEB", cursor: "pointer" }}
                onClick={() =>
                  handleRedirectToEvents(item.provider_uuid, check.provider_check_name)
                }
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  sx={{ width: "70%", padding: "12px 20px", borderRight: "0.5px solid #EBEBEB" }}
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/text_check.png`}
                    style={{ width: "18px", height: "18px", objectFit: "contain" }}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--black)",
                      opacity: 0.87,
                    })}
                  >
                    {check.provider_check_display_name}
                  </Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  gap={1.5}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "30%", padding: "12px 20px" }}
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${
                      eventHealthandIcon[check.health]
                    }`}
                    style={{ width: "14px", height: "14px", objectFit: "contain" }}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-800)",
                    })}
                  >
                    {check.health_display_name}
                  </Typography>
                  <ExpandMoreIcon
                    sx={{
                      transform: "rotate(270deg)",
                      color: "var(--dimmed-black)",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      );
      })}
      </Box>
    </Box>
  );
};

export default ReportsList;
