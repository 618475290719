import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionAlerts from "../components/common/ui-components/TransitionAlerts";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { typography } from "../shared/theme-constants";
import { GTAG_EVENTS, sendDataToGTM } from "../utils/gtmHelper";
import { buildUrl } from "../utils/stringUtils";
import { useDispatch } from "react-redux";
import { TENANT_UPDATE_DEMO_MODE } from "../constants/tenantConstants";

const getInTouchUrl = "https://outoftheblue.ai/contact-us/";
const windowName = "_blank";
const errorMessages = {
  invalid_domain: "Please use your work email",
};

function getDocsRedirectPathname(location) {
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.get("redirect") === "docs") {
    const docsReturnUrl = searchParams.get("location");
    const docsHref = buildUrl("docs", { location: docsReturnUrl });

    // remove origin from given href so we only have pathname and search params
    // as those are only values we need to sent to server as instruction for redirect
    return docsHref.replace(new URL(docsHref).origin, "");
  }

  return undefined;
}

const LoginButton = styled(Button)(({ theme }) => ({
  height: "47px",
  background: "#FFFFFF",
  "&:hover": {
    background: "#FFFFFF",
  },
  ":hover": {
    cursor: "pointer",
  },
  alignSelf: "center",
  marginTop: "5px",
  marginBottom: "5px",
  width: "100%",
  color: "#424242",
  border: "1px solid #3063E7",
  borderRadius: "10px",
  fontSize: theme.typography.pxToRem(12),
  ":disabled": {
    background: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)",
    borderColor: "transparent",
    cursor: "default",
  },
}));

const EmailTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "&:hover fieldset": {
      borderColor: "#E0E0E0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3063E7",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px",
  },
  "& .MuiInputLabel-root": {
    color: "var(--grey-800)",
    fontSize: theme.typography.pxToRem(12),
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(14px, 14px) scale(1)",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
  "& .MuiFormHelperText-root": {
    fontSize: theme.typography.pxToRem(10),
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0",
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "0",
  },
  "& .MuiOutlinedInput-multiline.MuiOutlinedInput-input": {
    padding: "0",
  },
}));

const LoginScreen = () => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state to track loading status
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnUrl = location.state?.from?.pathname || getDocsRedirectPathname(location) || "/";

  // Get the query params from the URL
  const searchParams = new URLSearchParams(location.search);

  // Get the query param for the error message
  const errorMessage = searchParams.get("error");

  // Get the shopify_data from the query params
  let shopify_data = searchParams.get("shopify_data");

  // Get the preferred_plan from the query params
  let preferred_plan = searchParams.get("plan") ? searchParams.get("plan") : "shopify";

  // Check if demo mode, if yes, then dispatch dispatch({ type: TENANT_UPDATE_DEMO_MODE, payload: true });
  if (searchParams.get("mode") === "demo") {
    // Dispatch the action to update the demo mode
    dispatch ({ type: TENANT_UPDATE_DEMO_MODE, payload: true });
    // Add to local storage
    window.localStorage.setItem("demo_mode", "true");
  }

  // Send the event to Google Tag Manager, if there is an error message
  if (errorMessage) {
    sendDataToGTM(GTAG_EVENTS.USER_LOGIN_ERROR, {
      error: errorMessage,
    });
  }

  const initializeDevRev = () => {
    window.plugSDK.init({
      app_id: process.env.REACT_APP_DEV_REV_APP_ID
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEV_REV_APP_ID && process.env.REACT_APP_DEV_REV_PUBLIC_AUTH_TOKEN) {
      initializeDevRev();
    }
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CDN_BASE_URL}/Testimonials/client_testimonials.json`
        );
        if (response) {
          setTestimonials(response.data);
        }
      } catch (error) {
        if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
          return navigate("/login");
        }
      }
    };
    fetchTestimonials();
  }, [navigate]);

  const handleGoogleLogin = () => {
    // Redirect to the Google OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      let googleLoginUrl = buildUrl("auth/google", { redirectTo: returnUrl, plan: preferred_plan });
      // Add the shopify_data to the query params
      googleLoginUrl = googleLoginUrl + `&shopify_data=${shopify_data}`;
      window.open(googleLoginUrl, "_self");
    } else {
      const googleLoginUrl = buildUrl("auth/google", { redirectTo: returnUrl, plan: preferred_plan });
      window.open(googleLoginUrl, "_self");
    }
  };

  const handleMicrosoftLogin = () => {
    // Redirect to the Microsoft OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      let microsoftLoginUrl = buildUrl("auth/microsoft", { redirectTo: returnUrl, plan: preferred_plan });
      // Add the shopify_data to the query params
      microsoftLoginUrl = microsoftLoginUrl + `&shopify_data=${shopify_data}`;
      window.open(microsoftLoginUrl, "_self");
    } else {
      const microsoftLoginUrl = buildUrl("auth/microsoft", { redirectTo: returnUrl, plan: preferred_plan });
      window.open(microsoftLoginUrl, "_self");
    }
  };

  const handleOktaLogin = () => {
    // Redirect to the Okta OAuth login endpoint on your Express server.

    // Check if the shopify_data is present in the query params
    if (shopify_data) {
      // Replace spaces with '+' in the shopify_data
      shopify_data = shopify_data.replace(/ /g, "+");
      let oktaLoginUrl = buildUrl("auth/okta", { redirectTo: returnUrl, plan: preferred_plan });
      // Add the shopify_data to the query params
      oktaLoginUrl = oktaLoginUrl + `&shopify_data=${shopify_data}`;
      window.open(oktaLoginUrl, "_self");
    } else {
      const oktaLoginUrl = buildUrl("auth/okta", { redirectTo: returnUrl, plan: preferred_plan });
      window.open(oktaLoginUrl, "_self");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleMagicLogin = async () => {
    const isValidEmail = validateEmail(document.getElementById("email").value);
    if (!isValidEmail) {
      setInvalidEmail(true);
      return;
    } else {
      setIsLoading(true);
      let magicLoginUrl;
      let requestBody;
      // Redirect to the Magic OAuth login endpoint on your Express server.

      // Check if the shopify_data is present in the query params
      if (shopify_data) {
        // Replace spaces with '+' in the shopify_data
        shopify_data = shopify_data.replace(/ /g, "+");
        magicLoginUrl = buildUrl("auth/magic-login", { redirectTo: returnUrl });
        requestBody = {
          destination: document.getElementById("email").value,
          shopify_data: shopify_data,
          plan: preferred_plan,
          is_demo: searchParams.get("mode") === "demo",
        };
      } else {
        magicLoginUrl = buildUrl("auth/magic-login", { redirectTo: returnUrl });
        requestBody = {
          destination: document.getElementById("email").value,
          plan: preferred_plan,
          is_demo: searchParams.get("mode") === "demo",
        };
      }
      try {
        const response = await axios.post(magicLoginUrl, requestBody);
        const { success, message } = response.data;

        if (success) {
          setAlertMessage("Please check your email for the login link");
          setAlertType("success");
          document.getElementById("email").value = "";
        } else {
          setAlertMessage(message);
          setAlertType("error");
        }
      } catch (error) {
        setAlertMessage("An error occurred while sending the request");
        setAlertType("error");
      } finally {
        setIsLoading(false); // Re-enable the button after the request is done (success or error)
      }
    }
  };

  return (
    <Container
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--white)",
        [theme.breakpoints.down("lt")]: { height: "100%" },
      })}
    >
      <Box
        sx={(theme) => ({
          position: "absolute",
          left: "64px",
          top: "38px",
          width: "auto",
          display: "flex",
          flexDirection: "row",
        })}
      >
        <img
          src={`${process.env.REACT_APP_CDN_BASE_URL}/otb_logo_with_name.png`}
          alt="Out Of The Blue"
          width="204px"
          height="44px"
        />
      </Box>
      <Stack
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        width="100%"
        sx={(theme) => ({
          [theme.breakpoints.down("lt")]: { flexDirection: "column" },
        })}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
            width: useMediaQuery("(min-width:600px)") ? "500px" : "100vw",
            maxWidth: "500px",
            boxShadow: "none",
            border: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "120px",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            {searchParams.get("mode") === "demo" && (<Typography
              variant="h5"
              sx={{
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-900)",
                textAlign: "center",
              }}
            >
              Sign up and Test Drive
              <br />Just in 5 minutes!
            </Typography>)}
            {searchParams.get("mode") != "demo" && (<Typography
              variant="h5"
              sx={{
                fontWeight: typography.font_weight.mediumx,
                color: "var(--grey-900)",
                textAlign: "center",
              }}
            >
              Go Live in 15 mins,
              <br />3 steps to get started!
            </Typography>)}
            {alertMessage && (
              <TransitionAlerts
                alert_message={alertMessage}
                alert_type={alertType}
                style={
                  alertType === "success"
                    ? {
                      backgroundColor: "rgb(237, 247, 237)",
                      color: "#1E4620",
                      width: "auto",
                      marginTop: "10px",
                    }
                    : { width: "auto", marginTop: "10px" }
                }
                handleCloseAlert={() => {
                  setAlertMessage("");
                  setAlertType("");
                }}
              />
            )}
            {errorMessage && (
              <div
                style={{
                  height: "41px",
                  width: "80%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <TransitionAlerts
                  alert_message={`Error: ${errorMessages[errorMessage]}`}
                  alert_type="error"
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: "10px",
                width: "80%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "15px",
                }}
              >
                <LoginButton
                  onClick={handleGoogleLogin}
                  startIcon={
                    <img
                      src={`${process.env.REACT_APP_CDN_BASE_URL}/images/google-logo.png`}
                      alt="Google"
                      width="20px"
                      height="20px"
                    />
                  }
                >
                  Continue with Google
                </LoginButton>
                <LoginButton
                  onClick={handleMicrosoftLogin}
                  startIcon={
                    <img
                      src={`${process.env.REACT_APP_CDN_BASE_URL}/images/microsoft-logo.png`}
                      alt="Google"
                      width="20px"
                      height="20px"
                    />
                  }
                >
                  Continue with Microsoft
                </LoginButton>
              </div>
              <Divider
                sx={{
                  width: "80%",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: "var(--grey-500)",
                    fontWeight: typography.font_weight.light,
                  }}
                >
                  or
                </Typography>
              </Divider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <EmailTextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    border: "1px solid #3063E7",
                    borderRadius: "10px",
                  }}
                  onChange={(e) => {
                    setInvalidEmail(false);
                  }}
                />
                {invalidEmail ? (
                  <Typography
                    sx={(theme) => ({
                      color: "var(--red-3)",
                      fontSize: theme.typography.pxToRem(12),
                      marginLeft: "0.75rem",
                      width: "100%",
                    })}
                  >
                    Please enter valid email.
                  </Typography>
                ) : null}
                <LoginButton
                  onClick={handleMagicLogin}
                  disabled={isLoading} // Disable the button when loading
                  sx={{
                    backgroundColor: "#3063E7",
                    color: "#FAFAFA",
                    "&:hover": {
                      backgroundColor: "#3063E7",
                    },
                    border: "1px solid #3063E7",
                    marginTop: "20px",
                  }}
                >
                  Continue with Email
                </LoginButton>
              </div>
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "normal",
                  color: "#616161",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                By signing up, you agree to our&nbsp;
                <span
                  style={{
                    color: "var(--grey-900)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(process.env.REACT_APP_TERMS_SERVICE_URL, windowName)}
                >
                  Terms of Service&nbsp;
                </span>
                and&nbsp;
                <span
                  style={{
                    color: "var(--grey-900)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(process.env.REACT_APP_PRIVACY_POLICY_URL, windowName)}
                >
                  Privacy Policy
                </span>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                width: "90%",
              }}
            >
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: typography.font_weight.light,
                  color: "#616161",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Already have an account?&nbsp;
                <span
                  style={{
                    color: "#3063E7",
                    cursor: "pointer",
                    fontSize: "13px",
                    fontWeight: typography.font_weight.semiBold,
                  }} // Different color and cursor for the sign-up link
                  onClick={() => navigate("/login")} // Redirect to the signup page
                >
                  Log in {"\u2192"}
                </span>
              </span>
            </div>
          </div>
        </Paper>
        <Paper
          elevation={3}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
            width: "656px",
            maxWidth: "656px",
            padding: "40px",
            backgroundColor: "var(--pale-ice-blue)",
            boxShadow: "none",
            borderRadius: "24px",
            [theme.breakpoints.down("lt")]: { width: "100%" },
          })}
        >
          <Stack flexDirection="column" justifyContent="space-between" sx={{ height: "inherit" }}>
            <Box gap={12} sx={{ display: "flex", flexDirection: "column" }}>
              <Stack flexDirection="column" gap={4}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(22),
                    fontWeight: typography.font_weight.bold,
                    color: "var(--charcoal-blue)",
                    [theme.breakpoints.down("lm")]: { fontSize: theme.typography.pxToRem(14) },
                  })}
                >
                  Unlock your full potential
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.regular,
                    color: "var(--charcoal-blue)",
                  })}
                >
                  Grow your top line and manage your bottom line by staying ahead of the curve!
                </Typography>
              </Stack>
              <Stack flexDirection="column" gap={3}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                    color: "var(--charcoal-blue)",
                  })}
                >
                  Used and loved by growth-oriented brands, including:
                </Typography>
                <Stack direction="row" gap={5} sx={{ flexWrap: "wrap" }}>
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/Brands/Logos/abbott_logo.png`}
                    alt="Abbott Lyon"
                    width="60px"
                    height="auto"
                    style={{ objectFit: "contain" }}
                  />
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/Brands/Logos/poshmark_logo.png`}
                    alt="Poshmark"
                    width="100px"
                    height="auto"
                    style={{ objectFit: "contain" }}
                  />
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/Brands/Logos/tbs_logo.png`}
                    alt="The Beard Struggle"
                    width="130px"
                    height="auto"
                    style={{ objectFit: "contain" }}
                  />
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/Brands/Logos/data_axle_logo.png`}
                    alt="Data Axle"
                    width="92px"
                    height="auto"
                    style={{ objectFit: "contain" }}
                  />
                </Stack>
              </Stack>
            </Box>
            <Box gap={4} sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.regular,
                  color: "var(--charcoal-blue)",
                  textAlign: "center",
                  [theme.breakpoints.up("tm")]: { fontSize: theme.typography.pxToRem(14) },
                })}
              >
                Out Of The Blue is your go-to health monitoring platform.
              </Typography>
              <Carousel
                autoPlay
                swipe={true}
                indicators={true}
                indicatorContainerProps={{
                  style: {
                    zIndex: 1,
                    // marginTop: "-10px",
                    position: "relative",
                  },
                }}
              >
                {testimonials.map((item, i) => (
                  <Paper
                    key={i}
                    elevation={3}
                    sx={{
                      padding: "28px",
                      backgroundColor: "var(--white)",
                      height: "245px",
                      boxShadow: "none",
                      borderRadius: "20px",
                      border: "0.5px solid #E5E7EB",
                    }}
                  >
                    <Stack direction="column" gap={5}>
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(14),
                          fontWeight: typography.font_weight.regular,
                          color: "var(--slate-gray)",
                          [theme.breakpoints.down("tm")]: {
                            fontSize: theme.typography.pxToRem(14),
                          },
                          [theme.breakpoints.down("st")]: {
                            fontSize: theme.typography.pxToRem(10),
                          },
                          [theme.breakpoints.down("lm")]: {
                            fontSize: theme.typography.pxToRem(10),
                          },
                        })}
                      >
                        {`"${item.description}"`}
                      </Typography>
                      <Stack direction="row" gap={4} alignItems="center">
                        <Avatar
                          alt="logo"
                          src={`${process.env.REACT_APP_CDN_BASE_URL}/Brands/brand-leaders/${item.logo}`}
                          sx={(theme) => ({
                            [theme.breakpoints.down("lm")]: { width: "28px", height: "28px" },
                          })}
                        />
                        <Stack direction="column" gap={1}>
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(16),
                              fontWeight: typography.font_weight.mediumx,
                              color: "var(--charcoal-blue)",
                              [theme.breakpoints.down("lt")]: {
                                fontSize: theme.typography.pxToRem(14),
                              },
                              [theme.breakpoints.down("st")]: {
                                fontSize: theme.typography.pxToRem(12),
                              },
                              [theme.breakpoints.down("lm")]: {
                                fontSize: theme.typography.pxToRem(10),
                              },
                            })}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(14),
                              fontWeight: typography.font_weight.mediumx,
                              color: "var(--tone-gray)",
                              [theme.breakpoints.down("lt")]: {
                                fontSize: theme.typography.pxToRem(14),
                              },
                              [theme.breakpoints.down("st")]: {
                                fontSize: theme.typography.pxToRem(10),
                              },
                              [theme.breakpoints.down("lm")]: {
                                fontSize: theme.typography.pxToRem(8),
                              },
                            })}
                          >
                            {`${item.position}, ${item.company}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Paper>
                ))}
              </Carousel>
            </Box>
          </Stack>
        </Paper>
      </Stack>
      <Box
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "40px",
          paddingRight: "40px",
          [theme.breakpoints.down("lt")]: {
            justifyContent: "center",
            position: "unset",
            paddingLeft: "0px",
            paddingRight: "0px",
          },
        })}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "#6B6D77",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              fontWeight: "bold",
              color: "#6B6D77",
            }}
          >
            Need Help? Email us:
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              color: "#6B6D77",
            }}
          >
            <a
              href="mailto:sales@outoftheblue.ai"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              sales@outoftheblue.ai
            </a>
          </span>
        </span>
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "#6B6D77",
            marginRight: "190px"
          }}
        >
          <a
            href="https://outoftheblue.ai/company/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ©2024 Out Of The Blue™
          </a>
        </span>
      </Box>
    </Container>
  );
};
export default LoginScreen;
