import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useAxios } from "../hooks/useAxios";
import { Loading } from "../components/common/styled-components";

const steps = ["Select Data Source", "Setup Data Set", "Configure Metrics", "Set Preferences", "Finish"];
const OnboardScreen = () => {
  const { data: availableConnectors, isLoading: loading } = useAxios("connectors", {
    params: {},
  });
  const [activeStep, setActiveStep] = useState(0);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const topPosition = window.localStorage.getItem("demo_mode") === "true" ? "var(--navbar-demo-height)" : "var(--navbar-height)";
  if (loading) {
    return (
      <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: `calc( ${topPosition} + 40px)` }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ maxWidth: "1100px", width: "100%" }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <Outlet context={[activeStep, setActiveStep, selectedConnector, setSelectedConnector, availableConnectors]}></Outlet>
    </>
  );
};

export default OnboardScreen;
