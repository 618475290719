import React, { useEffect, useState } from "react";
import { withScope, captureMessage } from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { compareMetrics } from "../../../actions/collaborateFilterActions";
import { BAR_CHART, KPIS, METRIC_COMPARE } from "../../../constants/multiSelectFilterConstants";
import { generateDimensionListQueryParam, getQueryParamsFromFilterObject } from "../../../utils/processFilterParams";
import { Loading } from "../../common/styled-components";

import { ChartContainerL3 } from "../../common/styled-components/collaborate";
import { Message } from "../../common/ui-components/Message";
import { CompareMetricsChart } from "./CompareMetricsChart";

function CompareMetrics({ tenantId, kpiId, metric, pipelineSchedule, isForecastEnabled = true, subType = null, isTitleRequired = true, event_details_message = null, plotBands, timezone= null, isTimeRequired = false, isPixel = false, isDemoMode = false }) {
  const dispatch = useDispatch();
  const filterSelector = useSelector((state) => state.collaborateFilters);
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);

  const [metricsData, setMetricsData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const filterContainsMetric = filterSelector.hasOwnProperty(KPIS);
  const topMetric = filterContainsMetric ? filterSelector?.kpis[0] : metric;

  useEffect(() => {
    let isMounted = true;

    const fetchComparisonCharts =() => {
      setLoading(true);
      let dimensionsArrayString = generateDimensionListQueryParam(
        filterSelector?.selectedDimensionNames,
        filterSelector?.selectedDimensionValues
      );
      if (filterSelector.isFetched) {
        const selectedFilter = getQueryParamsFromFilterObject(
          filterSelector,
          tenantId,
          pipelineSchedule,
          null,
          globalFilter?.dimension_name,
          globalFilter?.dimension_value,
          dimensionsArrayString,
          isForecastEnabled,
          subType,
          event_details_message,
          isPixel,
          isDemoMode,
        );

        dispatch(compareMetrics(kpiId, selectedFilter))
          .then((response) => {
            if (response?.length === 0) {
              // Send alert on slack via sentry to inform about no data coming on charts.
              withScope((scope) => {
                scope.setLevel("info");
                scope.setTag("event_type", "events_L3");
                captureMessage(`API returned empty array in compare charts`, "Warning");
              });
            }
            if (isMounted) {
              setMetricsData(response);
              setLoading(false);
            }
          })
          .catch((err) => {
            setError(err);
              // Send alert on slack via sentry to inform about no data coming on charts.
              withScope((scope) => {
                scope.setLevel("error");
                scope.setTag("event_type", "events_L3");
                captureMessage(err, "error");
              });
          });
      }
    };
    if(filterSelector.chartType === METRIC_COMPARE || filterSelector.chartType === BAR_CHART){
      if ( filterSelector?.selectedDimensionValues?.length > 0 ) {
        setMetricsData([]);
        fetchComparisonCharts();
      }if(filterSelector?.selectedDimensionNames?.length === 0){
        setMetricsData([]);
        fetchComparisonCharts();
      }
    }

    return () => (isMounted = false);
  }, [dispatch, filterSelector, kpiId, pipelineSchedule, tenantId, globalFilter?.dimension_name, globalFilter?.dimension_value]);

  let content = null;
  if (loading) {
    content = <Loading />;
  } else if (error) {
    content = <Message>An error occurred while fetching the data. Please try again after sometime.</Message>;
  } else if (filterContainsMetric) {
    content = (
      <CompareMetricsChart
        data={metricsData}
        enableForecast={filterSelector.isForecastEnabled}
        enableLogScale={filterSelector.isLogScaleEnabled}
        topMetric={topMetric}
        pipelineSchedule={pipelineSchedule}
        isForecastEnabled={isForecastEnabled}
        groupingBy={filterSelector?.selectedDimensionValues?.length > 0 ? "dim_val" : "kpi_name"}
        // For enabling the charts to understand which dimension value series comes the first in the sequence
        firstSelectedDimension = {filterSelector?.selectedDimensionValues?.length > 0 ? filterSelector?.selectedDimensionValues[0] : null}
        isTitleRequired={isTitleRequired}
        plotBands={plotBands}
        timezone={timezone}
        isTimeRequired={isTimeRequired}
        showBarChart={filterSelector.chartType === BAR_CHART}
      />
    );
  }

  return <ChartContainerL3>{content}</ChartContainerL3>;
}

export default CompareMetrics;
