import { Tooltip, Typography } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme, size }) => ({
  borderColor: theme.palette.grey[300],
  maxWidth: 100,
  justifyContent: "center",
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 0,
    minWidth: 40,
    color: "#929292",
    backgroundColor: "#F0F0F0",
    ...(size === "small" && {
      paddingLeft: 14,
      paddingRight: 14,
    }),
    "&:not(:first-of-type)": {
      borderLeft: 0,
    },
    "&:not(:first-of-type), &:not(:last-of-type)": {
      borderTopLeftRadius: "0.2em",
      borderTopRightRadius: "0.2em",
      borderBottomLeftRadius: "0.2em",
      borderBottomRightRadius: "0.2em",
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
      opacity: 0.3,
    },
    "&.Mui-selected": {
      color: "var(--mariner)",
      backgroundColor: "#DEDEDE",
      transform: "scale(1.03)",
      "&:hover": {
        backgroundColor: "#DEDEDE",
      },
    },
  },
}));

const StyledToggleGroup = styled(MuiToggleButtonGroup)(({ theme, size }) => ({
  borderColor: theme.palette.grey[300],
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 0,
    minWidth: 40,
    color: "#5C6178",
    backgroundColor: " #F4F4F4",
    ...(size === "small" && {
      paddingLeft: 14,
      paddingRight: 14,
    }),
    "&:not(:first-of-type)": {
      borderLeft: 0,
    },

    "&:not(:first-of-type), &:not(:last-of-type)": {
      borderTopLeftRadius: "0.2em",
      borderTopRightRadius: "0.2em",
      borderBottomLeftRadius: "0.2em",
      borderBottomRightRadius: "0.2em",
      marginLeft: 0,
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
      opacity: 0.3,
    },
    "&.Mui-selected": {
      color: "var(--white)",
      backgroundColor: "var(--mariner)",
      transform: "scale(1.03)",
      "&:hover": {
        backgroundColor: "var(--mariner)",
      },
    },
  },
}));

export const ToggleButtonGroupWithIcon = ({ value, onChange, options, children, ...delegatedProps }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  // We should also be able to control the state of the component from outside.
  // this useeffect will make sure the toggle group updates when controlled externally
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  function handleChange(event, selectedValue) {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);

      if (typeof onChange === "function") {
        onChange(event, selectedValue);
      }
    }
  }

  return (
    <StyledToggleButtonGroup exclusive onChange={handleChange} value={selectedValue} {...delegatedProps}>
      {children}
    </StyledToggleButtonGroup>
  );
};

export const ToggleGroup = ({ value, onChange, options, children, ...delegatedProps }) => {
  const [selectedValue, setSelectedValue] = useState();

  // We should also be able to control the state of the component from outside.
  // this useeffect will make sure the toggle group updates when controlled externally
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  function handleChange(event, selectedValue) {
    if (selectedValue !== null) {
      if (typeof onChange === "function") {
        onChange(event, selectedValue);
      }
    }
  }

  return (
    <StyledToggleGroup exclusive onChange={handleChange} value={selectedValue} {...delegatedProps}>
      {options.map(({ label, value, description, ...rest }) => (
        <MuiToggleButton
          key={label}
          value={value}
          {...rest}
          sx={{
            "&.Mui-disabled": {
              pointerEvents: "auto",
              opacity: 'unset',
              cursor: 'not-allowed',
            },
          }}
        >
          <Tooltip key={label} title={description ? description : ""} placement="bottom-end">
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(14),
              })}
            >
              {label}
            </Typography>
          </Tooltip>
        </MuiToggleButton>
      ))}
    </StyledToggleGroup>
  );
};
