import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import { INACTIVE_STATUSES, RESOURCE_TYPE_EVENT_GROUP } from "../../../constants/commonConstants";
import { colors, typography } from "../../../shared/theme-constants";
import { formatDateForTimeChip } from "../../../utils/dateUtils";
import { isNullOrEmpty } from "../../../utils/is";
import { titleCase } from "../../../utils/stringUtils";
import Icon from "../../common/Icon";
import EventSeverityLabels from "./EventSeverityLabels";
import EventStatusLabels from "./EventStatusLabels";
import EventTypeLabel from "./EventTypeLabels";
import { EVENT_TYPE_OUTAGE } from "../../../constants/eventConstants";
import Feedback from "../../insights/insight/feedback/Feedback";

function EventDetailsSection({
  eventGroupId,
  eventStatus,
  type,
  impact,
  severity,
  source = "listing",
  eventSeqId,
  eventStartTime,
  eventEndTime,
  timezone,
  duration,
  description,
  action,
  providerDisplayName,
  providerHomePageUrl,
  providerStatusPageUrl,
  funnels = [],
}) {
  const [expandedPanels, setExpandedPanels] = useState(['events-details', 'what-does-this-mean', 'what-can-you-do']); // Track expanded accordions

  const handleAccordionChange = (panelKey) => (event, isExpanded) => {
    // Add or remove panelKey based on the accordion's state (expanded or collapsed)
    setExpandedPanels((prevState) => {
      if (isExpanded) {
        return [...prevState, panelKey]; // Add to the expanded state
      } else {
        return prevState.filter((panel) => panel !== panelKey); // Remove from the expanded state
      }
    });
  };

  return (
    <>
      <Accordion
        sx={(theme) => ({
          width: "100%",
          borderBottom: "1px solid var(--soft-gray)",
          boxShadow: "unset",
          borderRadius: "0px",
          height: "fit-content",
          padding: "16px",
          [theme.breakpoints.down("sl")]: {
            width: "100%",
          },
          "&::before": {
            content: "none", // Removes the pseudo-element
          },
          "&.Mui-expanded": {
            margin: 0, // Set margin to 0 when Accordion is expanded
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          },
        })}
        key="events-details"
        expanded={expandedPanels.includes("events-details")} // Check if this panel is expanded
        onChange={handleAccordionChange("events-details")} // Pass the UUID to the curried function
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: "0px",
            minHeight: "unset",
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0, // Set margin to 0 for the expanded AccordionSummary content
            },
            "&.Mui-expanded": {
              minHeight: "unset",
              paddingBottom: "16px",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(11),
                fontWeight: typography.font_weight.bold,
                opacity: 0.87,
              })}
            >
              Details
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px",
            "&:last-child": { paddingBottom: 0 },
            // maxHeight: "273px",
            overflow: "scroll",
          }}
        >
          <Stack direction="column" gap={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--dusty-gray)",
                })}
              >
                Event Id
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--carbon-black)",
                })}
              >
                {eventSeqId}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--dusty-gray)",
                })}
              >
                Start of Event
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--carbon-black)",
                })}
              >
                {formatDateForTimeChip(eventStartTime, null, timezone, "ddd, DD MMM, hh:mm A z")}
              </Typography>
            </Stack>
            {source === "view" && INACTIVE_STATUSES.includes(eventStatus) ? (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--dusty-gray)",
                  })}
                >
                  End of Event
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--carbon-black)",
                  })}
                >
                  {formatDateForTimeChip(eventEndTime, null, timezone, "ddd, DD MMM, hh:mm A z")}
                </Typography>
              </Stack>
            ) : null}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--dusty-gray)",
                })}
              >
                Duration
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--carbon-black)",
                })}
              >
                {duration}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--dusty-gray)",
                })}
              >
                Dollar Impact
              </Typography>
              <Typography
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color:
                    impact?.monetary?.value > 0
                      ? colors.SEVERITY.GREEN.medium
                      : !impact?.monetary
                      ? "var(--carbon-black)"
                      : "var(--red-3)",
                })}
              >
                {impact?.monetary ? (
                  <>
                    <Icon
                      name={
                        impact?.monetary?.value > 0 ? "arrow-positive-up" : "arrow-negative-down"
                      }
                      size="12px"
                    />
                    {impact?.monetary?.formatted_value}
                  </>
                ) : (
                  "-"
                )}
              </Typography>
            </Stack>
            {eventStatus && !isNullOrEmpty(eventStatus) ? (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--dusty-gray)",
                  })}
                >
                  Status
                </Typography>
                <EventStatusLabels
                  status={
                    eventStatus === "Active" ? "live" : eventStatus === "Closed" ? "closed" : null
                  }
                />
              </Stack>
            ) : null}
            {severity ? (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--dusty-gray)",
                  })}
                >
                  Severity
                </Typography>
                <EventSeverityLabels label={severity} severity={severity} />
              </Stack>
            ) : null}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--dusty-gray)",
                })}
              >
                Type
              </Typography>
              <EventTypeLabel label={titleCase(type)} />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: typography.font_weight.mediumx,
                  color: "var(--dusty-gray)",
                })}
              >
                Labels
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "2px", flexWrap: "wrap" }}>
              <EventTypeLabel label="Site Monitoring" />
              {Array.isArray(funnels) && funnels?.length > 0 && (
                <EventTypeLabel label={funnels[0]} />
              )}
              </Box>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {type !== EVENT_TYPE_OUTAGE ? (
        <Accordion
          sx={(theme) => ({
            width: "100%",
            borderBottom: "1px solid var(--soft-gray)",
            boxShadow: "unset",
            borderRadius: "0px",
            height: "fit-content",
            padding: "16px",
            [theme.breakpoints.down("sl")]: {
              width: "100%",
            },
            "&::before": {
              content: "none", // Removes the pseudo-element
            },
            "&.Mui-expanded": {
              margin: 0, // Set margin to 0 when Accordion is expanded
            },
            "&:last-of-type": {
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
            },
          })}
          key="what-does-this-mean"
          expanded={expandedPanels.includes("what-does-this-mean")} // Check if this panel is expanded
          onChange={handleAccordionChange("what-does-this-mean")} // Pass the UUID to the curried function
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              padding: "0px",
              minHeight: "unset",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0, // Set margin to 0 for the expanded AccordionSummary content
              },
              "&.Mui-expanded": {
                minHeight: "unset",
                paddingBottom: "16px",
              },
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(11),
                  fontWeight: typography.font_weight.bold,
                  opacity: 0.87,
                })}
              >
                What does this mean?
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "0px",
              "&:last-child": { paddingBottom: 0 },
              // maxHeight: "273px",
              // overflow: "scroll",
            }}
          >
            <List sx={{ padding: 0, listStyleType: "disc", paddingLeft: "16px" }}>
              {description?.split("\n").map((line, index) => (
                <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
                  <ListItemText
                    primary={
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(12),
                          fontWeight: typography.font_weight.regular,
                          color: "var(--dark-grey)",
                          whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                          wordBreak: "break-word", // Allows long words to break and wrap
                        })}
                      >
                        {/* {line} Keep the leading "-" */}
                        {line?.replace(/^\s*-\s*/, "")} {/* Remove leading dash and whitespace */}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Accordion
        sx={(theme) => ({
          width: "100%",
          borderBottom: "1px solid var(--soft-gray)",
          boxShadow: "unset",
          borderRadius: "0px",
          height: "fit-content",
          padding: "16px",
          [theme.breakpoints.down("sl")]: {
            width: "100%",
          },
          "&::before": {
            content: "none", // Removes the pseudo-element
          },
          "&.Mui-expanded": {
            margin: 0, // Set margin to 0 when Accordion is expanded
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          },
        })}
        key="what-can-you-do"
        expanded={expandedPanels.includes("what-can-you-do")} // Check if this panel is expanded
        onChange={handleAccordionChange("what-can-you-do")} // Pass the UUID to the curried function
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: "0px",
            minHeight: "unset",
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0, // Set margin to 0 for the expanded AccordionSummary content
            },
            "&.Mui-expanded": {
              minHeight: "unset",
              paddingBottom: "16px",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(11),
                fontWeight: typography.font_weight.bold,
                opacity: 0.87,
              })}
            >
              What can you do?
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px",
            "&:last-child": { paddingBottom: 0 },
            // maxHeight: "273px",
            // overflow: "scroll",
          }}
        >
          {type !== EVENT_TYPE_OUTAGE ? (
            <List sx={{ padding: 0, listStyleType: "disc", paddingLeft: "16px" }}>
              {action?.split("\n").map((line, index) => (
                <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
                  <ListItemText
                    primary={
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(12),
                          fontWeight: typography.font_weight.regular,
                          color: "var(--dark-grey)",
                          whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                          wordBreak: "break-word", // Allows long words to break and wrap
                        })}
                      >
                        {/* {line} Keep the leading "-" */}
                        {line?.replace(/^\s*-\s*/, "")} {/* Remove leading dash and whitespace */}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : null}
          {type === EVENT_TYPE_OUTAGE ? (
            <List sx={{ padding: 0, listStyleType: "disc", paddingLeft: "16px" }}>
              <ListItem
                key={0}
                onClick={() => window.open(providerStatusPageUrl, "_blank")}
                sx={{ padding: 0, display: "list-item", cursor: "pointer" }}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        fontWeight: typography.font_weight.regular,
                        textDecoration: 'underline',
                        color: "var(--vivid-blue)",
                        whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                        wordBreak: "break-word", // Allows long words to break and wrap
                      })}
                    >
                      {`Check ${providerDisplayName}'s Status Page`}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                key={0}
                onClick={() => window.open(providerHomePageUrl, "_blank")}
                sx={{ padding: 0, display: "list-item", cursor: "pointer" }}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        fontWeight: typography.font_weight.regular,
                        textDecoration: 'underline',
                        color: "var(--vivid-blue)",
                        whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                        wordBreak: "break-word", // Allows long words to break and wrap
                      })}
                    >
                      {`Visit ${providerDisplayName} Home Page`}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          ) : null}
        </AccordionDetails>
      </Accordion>
      <Box sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 2,
      }}>
        <Feedback resourceId={eventGroupId} resourceType={RESOURCE_TYPE_EVENT_GROUP}/>
      </Box>
    </>
  );
}

export default EventDetailsSection;
