import moment from "moment-timezone";
import React from "react";
import { useSelector } from "react-redux";
import { StyledCardV2 } from "../../common/styled-components/Card.styled";
import EventGroupCardHeader from "./EventCardHeader";

const DEFAULT_TIMEZONE = "Etc/UTC";

function EventGroup({ event }) {

  // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);

  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;

  const calculateDuration = () => {
    let start = moment(event?.event_group_ts_start).tz(DEFAULT_TIMEZONE);
    let end = moment(event?.event_group_ts_end).tz(DEFAULT_TIMEZONE);

    // If event is active, calculate duration till current time
    if (event?.status === "Active") {
      end = moment().tz(DEFAULT_TIMEZONE);
    }

    const duration = moment.duration(end.diff(start));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    // Construct output dynamically, skipping zero values
    let result = [];
    if (years > 0) result.push(`${years}y`);
    if (months > 0) result.push(`${months}m`);
    if (days > 0) result.push(`${days}d`);
    if (hours > 0) result.push(`${hours}h`);
    if (minutes > 0) result.push(`${minutes.toString().padStart(2, '0')}m`);

    return result.length ? result.join(' ') : '0m'; // If duration is zero, return '0m'
  };
  return (
      <StyledCardV2 pageType="events" eventseverity={event?.severity}>
        <EventGroupCardHeader
          categoryLogo={event?.category_logo_url}
          providerLogo={event?.provider_logo_url}
          eventStatus={event?.status}
          eventSeqId={event?.formatted_event_group_sequence_id}
          eventStartTime={event?.event_group_ts_start}
          eventEndTime={event?.event_group_ts_end}
          numberOfEvents={event?.number_of_events}
          type={event?.type}
          subType={event?.sub_type}
          eventTitle={event?.title}
          eventSummary={event?.summary}
          eventCategory={event?.category_display_name}
          providerDisplayName={event?.provider_display_name}
          severity={event?.severity}
          timezone={timezone}
          event_labels={event?.labels}
          duration={calculateDuration()}
          {...event}
        />
      </StyledCardV2>
  );
}

export default EventGroup;
