import React, { useEffect, useState } from "react";
import HighChartsReactComponent from "../../common/highcharts/HighChartsReactComponent";
import ListLoader from "../../common/ListLoader";
import Highcharts from "highcharts";
import "./EventDistributionChart.css";

const barColors = ["#F6C84A", "#42BE8B", "#1DA5E2", "#AEA6FA", "#E25D33", "#7B86C6"];

const EventsDistributionChart = ({ chart_data }) => {
  const [chartOptions, setChartOptions] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    generateChartData(chart_data);
  }, []);
  const generateChartOptions = (chartData) => {
    const totalImpactCount = chartData?.length ? chartData.reduce((acc, item) => acc + item.count, 0) : 0;
    // So, issue was that greyish part in the progress bar was inconsistent among different categories hence below code.
    const totalBarData = chartData?.length ? chartData.map(item => {
      const totalCategoryCount = totalImpactCount - item.count;
      return totalCategoryCount;
    }) : [];

    const chartConfig = {
      chart: {
        type: "bar",
        height: 70 + chartData?.length * 40, // Dynamic height: 60px base + 40px per bar
        spacingTop: 10,
        spacingBottom: 10,
      },
      title: {
        text: "",
      },
      xAxis: {
        lineWidth: 0, // yEH ADD
        categories: chartData?.length ? chartData.map(item => item.name) : [""],
        visible: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        visible: false,
        gridLineWidth: 0,
      },
      plotOptions: {
        series: {
          pointPadding: 0.1, // Minimal padding between bars
          groupPadding: 0.1, // Minimal group padding
          borderRadius: 5,
          pointWidth: 10,
          stacking: "normal",
        },
      },
      tooltip: {
        // Custom formatter to show count and percentage only for Progress series
        formatter: function () {
          // Show tooltip only for the Progress series
          if (this?.series?.name === "Progress" && totalImpactCount > 0) {
            const percentage = (this.y / totalImpactCount) * 100; // Calculate percentage based on total count
            return `${this.point.category}: <b>${this.y}</b> (${percentage.toFixed(1)}%)`;
          }
          return false; // Do not show tooltip for Total series
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Total",
          data: totalBarData.map(item => item),
          color: "rgba(200, 200, 200, 0.3)", // Background bar color
          borderRadius: 5,
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: false, // Disable tooltip for the Total series
          },
        },
        {
          name: "Progress",
          data: chartData.map((item, index) => ({
            y: item.count,
            color: barColors[index % barColors.length],
            category: item.name,
          })),
          borderRadius: 5,
          dataLabels: {
            enabled: true,
            align: "left",
            verticalAlign: "top",
            y: -30, // Space above the bar
            style: {
              fontWeight: 500,
              fontSize: "12px",
              color: "#171717",
            },
            formatter: function () {
              return this.point.category;
            },
          },
          tooltip: {
            enabled: true, // Enable tooltip for the Progress series
          },
        },
      ],
    };
    setChartOptions(chartConfig);
    setLoading(false);
  };

  const generateChartData = (chart_data) => {
    let chartData = [];
    let resultArray = [];
    let chartItems = chart_data?.items;
    for (let i = 0; i < chart_data?.items?.length; i++) {
      let temp_obj = {
        name: "",
        count: 0,
      };
      temp_obj.name = chartItems[i]?.name;
      temp_obj.count = chartItems[i]?.count;

      chartData.push(temp_obj);
    }

    if (chartData?.length > 6) {
      let slicedArray = chartData.slice(5);
      let othersCount = 0;
      slicedArray.map((item) => {
          othersCount += item?.count;
      });
      const startArray = chartData.slice(0, 5);
      resultArray = [...startArray];
      resultArray.push({
        name: "Others",
        count: othersCount,
      });
    } else {
      resultArray = [...chartData];
    }

    // pass the series info to chart options
    generateChartOptions(resultArray);
  };

  if (loading) return <ListLoader count={1} height={200} paddingY={10} />;

  return (
    <HighChartsReactComponent
      containerProps={{
        style: {
          overflow: "unset",
          height: "auto",
          width: "100%",
        },
      }}
      options={chartOptions}
    />
  );
};

export default EventsDistributionChart;
