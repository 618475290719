import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import "./ErrorDetailsPayload.css";

const ErrorDetailsPayload = ({ stackTrace, userDefinedCustomStyles, ...rest }) => {
  return (
    <SyntaxHighlighter
      wrapLines={true}
      language={Array.isArray(stackTrace) ? "javascript" : "text"}
      style={prism}
      customStyle={{
       ...userDefinedCustomStyles
      }}
      { ...rest }
    >
      {/* It handles the cases where stackTrace is not array. */}
      {Array.isArray(stackTrace) ? stackTrace.join("\n") : stackTrace}
    </SyntaxHighlighter>
  );
};

export default ErrorDetailsPayload;
