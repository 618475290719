import { Box, Stack, Typography } from "@mui/material";
import { withScope, captureMessage } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compareMetrics } from "../../../actions/collaborateFilterActions";
import { CHART_TYPE, METRIC_COMPARE } from "../../../constants/multiSelectFilterConstants";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { typography } from "../../../shared/theme-constants";
import { getQueryParamsFromFilterObject } from "../../../utils/processFilterParams";
import MultiSelect from "../../common/mui-wrapper-components/Select/MultiSelect";
import { Loading } from "../../common/styled-components";
import { Message } from "../../common/ui-components/Message";
import DimensionsBreakupChart from "./DimensionsBreakupChart";

const DimensionBreakupChartData = ({ metric, kpiId, dimensionId, pipelineSchedule, chartType, subType = null, isTimeRequired = false, timezone= null, pageType = null, plotBands }) => {

  const filterSelector = useSelector((state) => state.collaborateFilters);
  const [dimensionsBreakupData, setDimensionsBreakupData] = useState();
  const selectedTenant = useSelectedTenant();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const dispatch = useDispatch();
  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    setError(undefined);
    if (filterSelector?.isFetched) {
      const selectedFilter = getQueryParamsFromFilterObject(
        filterSelector,
        selectedTenant,
        pipelineSchedule,
        dimensionId,
        globalFilter?.dimension_name,
        globalFilter?.dimension_value,
        null,
        null,
        subType,
      );
      dispatch(compareMetrics(kpiId, selectedFilter))
        .then((response) => {
          if (response?.length === 0) {
            // Send alert on slack via sentry to inform about no data coming on charts.
            withScope((scope) => {
              scope.setLevel("info");
              scope.setTag("event_type", "events_L3");
              captureMessage(`API returned empty array in ${chartType} charts`, "Warning");
            });
          }
          if (isMounted) {
            setDimensionsBreakupData(response);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    }

    return () => (isMounted = false);
  }, [dispatch, dimensionId, filterSelector, pipelineSchedule, selectedTenant, kpiId, globalFilter?.dimension_name, globalFilter?.dimension_value, subType]);

  if (loading) return <Loading />;
  if (error) {
    return (
      <Message>An error occurred while fetching the data. Please try again after sometime.</Message>
    );
  }
  return (
    <DimensionsBreakupChart
      metric={metric}
      data={dimensionsBreakupData}
      chartType={chartType}
      pipelineSchedule={pipelineSchedule}
      isTimeRequired={isTimeRequired}
      timezone={timezone}
      pageType={pageType}
      plotBands={plotBands}
    />
  );
};

const DimensionsBreakup = ({ metric, chartType, kpiId, pipelineSchedule, subType = null, isTimeRequired = false, timezone= null, pageType = null, plotBands }) => {
  // Set the dimensions Data for Initial render
  const dispatch = useDispatch();
  const filterSelector = useSelector((state) => state.collaborateFilters.selectedDimensionNames);

  return (
    <Box height={450}>
      <Stack direction="column" gap={4} mt={5}>
        <DimensionBreakupChartData
          metric={metric}
          kpiId={kpiId}
          dimensionId={filterSelector.length ? filterSelector[0].dimension_id : undefined}
          pipelineSchedule={pipelineSchedule}
          chartType={chartType}
          subType={subType}
          isTimeRequired={isTimeRequired}
          timezone={timezone}
          pageType={pageType}
          plotBands={plotBands}
        />
      </Stack>
    </Box>
  );
};

export default DimensionsBreakup;
