import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SCROLL_LOCKING_ATTRIBUTE = "data-lock-scrolling";

const ModalContainer = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: var(--navbar-height);
  height: calc(100% - var(--navbar-height));
  overflow-y: auto;
`;

export const ModalRouteContainer = ({ children }) => {
  const [topPosition, setTopPosition] = useState("var(--navbar-height)");
  const isDemoMode = useSelector((state) => state.demoModeFilter?.isDemoMode);

  useEffect(() => {
    if (isDemoMode) {
      setTopPosition("var(--navbar-demo-height)");
    } else {
      setTopPosition("var(--navbar-height)");
    }
  }, [isDemoMode]);

  useEffect(() => {
    document.body.setAttribute(SCROLL_LOCKING_ATTRIBUTE, "");

    return () => {
      document.body.removeAttribute(SCROLL_LOCKING_ATTRIBUTE);
    };
  }, []);

  return <ModalContainer sx={{
    top: topPosition,
    height: `calc(100% - ${topPosition})`
  }}>{children}</ModalContainer>;
};
