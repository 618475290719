import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../shared/theme-constants";

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => props.topPosition};
  position: fixed;
  top: 0; 
  z-index: 999;
  background-color: ${colors.header_bg};
  background-image: linear-gradient(to right, #02096a, #1040c8);
`;

export const Header = () => {
  const [topPosition, setTopPosition] = useState("var(--navbar-height)");

  useEffect(() => {
    const demoMode = window.localStorage.getItem("demo_mode") === "true";
    setTopPosition(demoMode ? "var(--navbar-demo-height)" : "var(--navbar-height)");
  }, []);

  return <StyledHeader topPosition={topPosition} />;
};
