import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import React from "react";

const StyledChip = styled(Chip)(({ theme, padding }) => ({
  minWidth: theme.typography.pxToRem(70),
  height: theme.typography.pxToRem(30),
  borderRadius: theme.typography.pxToRem(6),
  "&& .MuiChip-label": {
    paddingLeft: padding ? `${padding} !important` : "0rem !important",
    paddingRight: padding ? `${padding} !important` : "0rem !important",
    fontFamily: "Inter !important",
    fontSize: "11px",
    fontWeight: 400,
  },
  "&& .MuiChip-icon": {
    margin: 0,
    marginRight: "4px",
  },
}));

const EventStatusLabels = ({status}) => {
  return (
    <StyledChip
      label={`${status}`}
      icon={
        <img
          src={`${process.env.REACT_APP_CDN_BASE_URL}/images/event_${status?.toLowerCase()}.png`}
          alt="icon"
          style={{
            width: "15px",
            height: "15px",
            marginLeft: "0px",
            marginRight: "4px",
          }}
        />
      }
      style={{
        background: "var(--light-grey)",
        color: "var(--charcoal-grey)",
        fontSize: "14px",
      }}
      className="provider-tag"
    />
  );
};

export default EventStatusLabels;
