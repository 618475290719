import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Stack,
  Tab,
  ToggleButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { sub } from "date-fns";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EventExtentDistributionTable from "../components/Events/EventExtentDistributionTable";
import EventDetailCards from "../components/Events/components/EventDetailCards";
import EventDetailsSection from "../components/Events/components/EventDetailsSection";
import EventPayloadTable from "../components/Events/components/EventPayloadTable";
import EventTitleSection from "../components/Events/components/EventTitleSection";
import EventsDistributionChart from "../components/Events/components/EventsDistributionChart";
import EventsTimelineChart from "../components/Events/components/EventsTimelineChart";
import Icon from "../components/common/Icon";
import ApiScreenError from "../components/common/error-components/ApiScreenError";
import { ToggleButtonGroupWithIcon } from "../components/common/mui-wrapper-components/StyledToggleGroup";
import { CopyLink } from "../components/common/share-and-collaborate/CopyLink";
import { LoaderInCenter } from "../components/common/styled-components";
import { L3ContainerMain } from "../components/common/styled-components/collaborate";
import { Message } from "../components/common/ui-components/Message";
import {
  BROWSER_EVENT_SUB_TYPES,
  EVENT_SOURCE_IMPACT,
  EVENT_SUB_TYPE_CDP,
  EVENT_SUB_TYPE_EXPERIRENCE,
  EVENT_TYPE_ERROR,
  EVENT_TYPE_OUTAGE,
} from "../constants/eventConstants";
import { BAR_CHART, TABLE_DATA } from "../constants/multiSelectFilterConstants";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { errorMessage } from "../shared/intl/error-messages";
import { typography } from "../shared/theme-constants";
import EventHotspotChart from "../components/Events/components/EventHotspotChart";

const DEFAULT_TIMEZONE = "Etc/UTC";

const CHART_TYPES = [
  { name: "bar_chart", value: BAR_CHART, key: "bar_chart", description: "Chart View" },
  {
    name: "table_view",
    value: TABLE_DATA,
    key: "table_data",
    description: "Table view",
  },
];

const EventGroupCollaborateScreen = () => {
  const [tabValue, setTabValue] = useState(0);

  const selectedTenant = useSelectedTenant();
  const [selectedChartType, setSelectedChartType] = useState(CHART_TYPES[0].value);
  // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);
  const globalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const isDemoMode = useSelector((state) => state.demoModeFilter?.isDemoMode);
  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lt"));
  const { eventGroupId } = useParams();
  const {
    data: eventGroupData,
    isLoading,
    isError,
    error,
  } = useAxios(`events/groups/${eventGroupId}`, {
    params: {
      tenant_id: selectedTenant,
      ...(isDemoMode && { is_demo: true }),
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabValueChange = (event, newValue) => {
    // If the tab value is Event source impact
    // default the charat type to  bar chart
    // while returning from event source impact to other tabs
    // default the chart type to bar chart
    if (newValue === EVENT_SOURCE_IMPACT) {
      setSelectedChartType(BAR_CHART);
      setTabValue(newValue);
    } else {
      setSelectedChartType(BAR_CHART);
      setTabValue(newValue);
    }
  };

  const calculateDuration = () => {
    let current_time_stamp = null;
    let event_group_start_time = moment(eventGroupData?.event_group_ts_start).tz(DEFAULT_TIMEZONE);

    let event_group_duration = 0;

    if (eventGroupData?.status === "Active") {
      // Get browser's Time
      current_time_stamp = moment().tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    } else if (eventGroupData?.status === "Closed") {
      current_time_stamp = moment(eventGroupData?.event_group_ts_end).tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    }

    const days = event_group_duration.days();
    const hours = event_group_duration.hours().toString().padStart(2, "0");
    const minutes = event_group_duration.minutes().toString().padStart(2, "0");
    let formattedDuration = `${days > 0 ? days + "d" : ""} ${hours > 0 ? hours + "h" : ""} ${
      minutes > 0 ? minutes + "mins" : ""
    }`;
    if (days <= 0 && hours <= 0 && minutes <= 0) {
      formattedDuration = "-";
    }
    return formattedDuration;
  };

  if (isLoading) {
    return (
      <L3ContainerMain id={eventGroupId} style={{ backgroundColor: "var(--grey-50)" }}>
        <LoaderInCenter />
      </L3ContainerMain>
    );
  }

  if (isError) {
    if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
      return <ApiScreenError error={error} resourceType="Group" />;
    } else {
      return (
        <Box pb="50px">
          <Message>{errorMessage.CARD_ERROR}</Message>
        </Box>
      );
    }
  }
  const renderEventsDetailsBlock = () => {
    return (
      <Stack
        direction="column"
        sx={(theme) => ({
          height: "100%",
          width: "23%",
          backgroundColor: "var(--white)",
          [theme.breakpoints.down("lt")]: { width: "100%" },
        })}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--soft-gray)",
            padding: "18px 16px",
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(14),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--midnight-gray)",
            })}
          >
            Details
          </Typography>
        </Box>
        <EventDetailsSection
          eventGroupId={eventGroupData?.event_group_id}
          eventStatus={eventGroupData?.status}
          eventSeqId={eventGroupData?.formatted_event_group_sequence_id}
          eventStartTime={eventGroupData?.event_group_ts_start}
          eventEndTime={eventGroupData?.event_group_ts_end}
          type={eventGroupData?.type}
          funnels={eventGroupData?.funnels}
          duration={calculateDuration()}
          timezone={timezone}
          description={eventGroupData?.description}
          source="view"
          providerDisplayName={eventGroupData?.provider_display_name}
          providerHomePageUrl={eventGroupData?.provider_home_page_url}
          providerStatusPageUrl={eventGroupData?.provider_status_page_url}
          eventLabels={eventGroupData?.labels}
          {...eventGroupData}
        />
      </Stack>
    );
  };

  return (
    <L3ContainerMain
      id={eventGroupId}
      style={{
        backgroundColor: "var(--grey-50)",
        alignItems: "center",
        ...(isSmallScreen && { position: "fixed", height: "auto", overflowY: "auto" }),
      }}
    >
      <Box
        sx={(theme) => ({
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "40px",
          paddingBottom: "50px",
          width: "100%",
          [theme.breakpoints.down("lt")]: { overflow: `scroll`, marginTop: "0px" },
        })}
      >
        <Stack
          direction="row"
          sx={(theme) => ({
            width: "100%",
            justifyContent: "end",
            [theme.breakpoints.down("lt")]: { flexDirection: "column" },
          })}
        >
          <Stack
            // gap={7}
            direction="column"
            sx={(theme) => ({
              height: "100%",
              // maxWidth: theme.typography.pxToRem(1200),
              width: "77%",
              // padding: "0 20px",
              backgroundColor: "var(--white)",
              border: "1px solid var(--soft-gray)",
              [theme.breakpoints.down("lt")]: { width: "100%" },
            })}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={3}
              sx={(theme) => ({
                backgroundColor: "var(--pale-lilac)",
                padding: "12px 16px",
                [theme.breakpoints.up("lt")]: { display: "none" },
              })}
            >
              <img
                src={`${process.env.REACT_APP_CDN_BASE_URL}/icons/events_icon.svg`}
                style={{
                  width: "12px",
                  height: "12px",
                  objectFit: "contain",
                  marginRight: "4px",
                }}
              />
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(14),
                  fontWeight: typography.font_weight.regular,
                  color: "var(--carbon-black)",
                })}
              >
                For the best experience, please switch to a desktop browser.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={3}
              sx={{
                alignItems: "center",
                borderBottom: "1px solid var(--soft-gray)",
                padding: "18px 16px",
                height: "57px",
              }} // Removed mt: 8 from here.
              justifyContent="space-between"
            >
              <Stack direction="row" gap={3} alignItems="center">
                <img
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/icons/events_icon.svg`}
                  style={{
                    width: "12px",
                    height: "12px",
                    objectFit: "contain",
                    marginRight: "4px",
                  }}
                />
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={(theme) => ({
                    [theme.breakpoints.down("lt")]: {
                      display: "none",
                    },
                  })}
                />
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.regular,
                    color: "var(--steel-gray)",
                    cursor: "pointer",
                  })}
                  onClick={() => navigate(-1)}
                >
                  Events
                </Typography>
                <img
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/icons/arrow_right.svg`}
                  style={{
                    width: "4px",
                    height: "8px",
                    objectFit: "contain",
                    marginRight: "4px",
                  }}
                />
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--midnight-gray)",
                  })}
                >
                  {eventGroupData?.formatted_event_group_sequence_id}
                </Typography>
              </Stack>
              <CopyLink
                link={`events/groups/${eventGroupData.event_group_id}?dim_name=${encodeURIComponent(
                  globalFilter?.dimension_name
                )}&dim_val=${encodeURIComponent(
                  globalFilter?.dimension_value
                )}&tenant_id=${encodeURIComponent(selectedTenant)}`}
                suffixlabel="Share"
                customicon={
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/share.png`}
                    style={{
                      width: "16px",
                      height: "16px",
                      objectFit: "contain",
                      marginRight: "4px",
                    }}
                  />
                }
                style={{
                  backgroundColor: "var(--white)",
                  borderRadius: "8px",
                  border: "1px solid #EBEAED",
                  color: "var(--black)",
                  fontSize: "14px",
                }}
              />
            </Stack>
            <Box style={{ position: "relative" }}>
              <EventTitleSection
                providerLogo={eventGroupData?.provider_logo_url}
                numberOfEvents={eventGroupData?.number_of_events}
                type={eventGroupData?.type}
                eventCategory={eventGroupData?.category_display_name}
                providerDisplayName={eventGroupData?.provider_display_name}
                isDescriptionSectionEnabled={true}
                {...eventGroupData}
              />
            </Box>
            {/* Below code line is for mobile screen cases where we need details section just below title part so when it's small screen then it will come here else below */}
            {isSmallScreen ? renderEventsDetailsBlock() : null}
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ border: "1px solid var(--soft-gray)", borderRadius: "6px" }}>
                {eventGroupData?.sub_type !== EVENT_SUB_TYPE_CDP && eventGroupData?.kpi_id ? ( // CDP Subtypes are the only place where charts are not being shown.
                  <EventsTimelineChart
                    startTime={
                      eventGroupData?.type === EVENT_TYPE_OUTAGE && eventGroupData?.kpi_id
                        ? new Date(eventGroupData?.event_group_ts_end)
                        : new Date(eventGroupData?.event_group_ts_start)
                    }
                    endTime={eventGroupData?.event_group_ts_end?.split("T")[0] + "T23:59:59.999Z"}
                    eventSubType={
                      eventGroupData?.type === EVENT_TYPE_OUTAGE
                        ? EVENT_SUB_TYPE_EXPERIRENCE
                        : eventGroupData?.sub_type
                    }
                    kpiId={eventGroupData?.kpi_id}
                    kpiDisplayName={eventGroupData?.kpi_display_name}
                    kpiName={eventGroupData?.kpi_name}
                    tenantId={eventGroupData?.tenant_id}
                    pipelineSchedule="h"
                    event_details_message={
                      eventGroupData?.details?.event_classification?.error_message
                    }
                    eventGroupStart={eventGroupData?.event_group_ts_start}
                    eventGroupEnd={eventGroupData?.event_group_ts_end}
                    isOverlayRequired={!BROWSER_EVENT_SUB_TYPES.includes(eventGroupData?.sub_type)}
                    metric={{
                      dimensions: eventGroupData?.dimensions,
                      kpi_format: eventGroupData?.kpi_format,
                    }}
                    eventLogsData={eventGroupData?.events?.length ? eventGroupData?.events : []}
                    eventType={eventGroupData?.type}
                  />
                ) : null}
                {eventGroupData?.sub_type === EVENT_SUB_TYPE_EXPERIRENCE &&
                eventGroupData?.events?.length ? (
                  <EventHotspotChart event_group={eventGroupData} />
                ) : null}
                {eventGroupData?.type === EVENT_TYPE_ERROR &&
                BROWSER_EVENT_SUB_TYPES.includes(eventGroupData?.sub_type) &&
                eventGroupData.percentage_extent_distribution !== null ? (
                  <Stack
                    direction="row"
                    sx={(theme) => ({
                      justifyContent: "space-between",
                      gap: 4,
                    })}
                  >
                    <EventDetailCards
                      sx={(theme) => ({
                        width: "100%",
                        boxShadow: "0px 2px 4px 0px #00000014",
                        padding: 0,
                      })}
                      style={{ overflowY: "auto", padding: "24px", borderRadius: "0px" }}
                      cardHeaderStyles={{ width: "100%" }}
                      image={`${eventGroupData.percentage_extent_distribution?.section_name}`}
                      // title={`${eventGroupData.percentage_extent_distribution?.section_display_name}`}
                      title={
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(18),
                              fontWeight: typography.font_weight.semiBold,
                              color: "var(--charcoal-grey)",
                            })}
                          >
                            {eventGroupData.percentage_extent_distribution?.section_display_name}
                          </Typography>
                          <ToggleButtonGroupWithIcon
                            value={selectedChartType}
                            onChange={(event, value) => setSelectedChartType(value)}
                            sx={{
                              width: "100%",
                              height: 38,
                            }}
                          >
                            {CHART_TYPES.map((chartType) => (
                              <Tooltip
                                key={chartType.key}
                                title={chartType.description}
                                placement="bottom-end"
                              >
                                <ToggleButton
                                  value={chartType.value}
                                  key={chartType.key}
                                  disabled={
                                    tabValue === EVENT_SOURCE_IMPACT &&
                                    chartType.value === TABLE_DATA
                                  }
                                >
                                  <Icon
                                    name={chartType.name}
                                    color={selectedChartType === chartType.value ? "#245ae6" : null}
                                  ></Icon>
                                </ToggleButton>
                              </Tooltip>
                            ))}
                          </ToggleButtonGroupWithIcon>
                        </Stack>
                      }
                    >
                      <Box
                        mt={7}
                        mb={5}
                        height="auto"
                        sx={{
                          marginTop: 0,
                        }}
                      >
                        <TabContext value={tabValue}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleTabValueChange}
                              aria-label="lab API tabs example"
                            >
                              {eventGroupData?.percentage_extent_distribution?.distributions?.map(
                                (distribution, index) => (
                                  <Tab
                                    key={index}
                                    label={distribution?.section_display_name}
                                    value={index}
                                    sx={{
                                      color: "var(--greyish-lavender)", // Default text color
                                      "&.Mui-selected": {
                                        color: "var(--charcoal-grey)", // Selected text color
                                      },
                                    }}
                                  />
                                )
                              )}
                              {/* Add section which is specific for event source */}
                              <Tab
                                key={EVENT_SOURCE_IMPACT}
                                label={`Event Source Impact`}
                                value={EVENT_SOURCE_IMPACT}
                                sx={{
                                  color: "var(--greyish-lavender)", // Default text color
                                  "&.Mui-selected": {
                                    color: "var(--charcoal-grey)", // Selected text color
                                  },
                                }}
                              />
                            </TabList>
                          </Box>
                          {eventGroupData?.percentage_extent_distribution?.distributions?.map(
                            (distribution, index) => (
                              <React.Fragment key={index}>
                                <TabPanel value={index} sx={(theme) => ({ padding: 0 })}>
                                  <Stack direction="column" gap={6}>
                                    {eventGroupData?.details?.impacted_areas &&
                                      eventGroupData?.details?.impacted_areas?.map(
                                        (impacted_area, impacted_area_index, arr) => {
                                          // Check condition before rendering
                                          if (
                                            impacted_area.section_name === distribution.section_name
                                          ) {
                                            if (selectedChartType === BAR_CHART) {
                                              return (
                                                <EventDetailCards
                                                  key={impacted_area_index}
                                                  sx={(theme) => ({
                                                    width: "100%",
                                                    boxShadow: "0px 2px 4px 0px #00000014",
                                                  })}
                                                  style={{ overflowY: "auto" }}
                                                  cardContentStyles={{
                                                    padding: 0,
                                                  }}
                                                >
                                                  <Stack
                                                    direction="column"
                                                    sx={{ height: "100%", maxHeight: 400, mt: 5 }}
                                                  >
                                                    <Stack direction="column" ml={2} gap={2}>
                                                      <Typography
                                                        sx={(theme) => ({
                                                          fontSize: theme.typography.pxToRem(14),
                                                        })}
                                                      >
                                                        Total
                                                      </Typography>
                                                      <Typography
                                                        sx={(theme) => ({
                                                          fontSize: theme.typography.pxToRem(20),
                                                          fontWeight:
                                                            typography.font_weight.mediumx,
                                                        })}
                                                      >
                                                        {impacted_area.total_count}
                                                      </Typography>
                                                    </Stack>
                                                    <EventsDistributionChart
                                                      chart_data={impacted_area}
                                                    />
                                                  </Stack>
                                                </EventDetailCards>
                                              );
                                            } else {
                                              return (
                                                <EventExtentDistributionTable
                                                  key={impacted_area_index}
                                                  pages={distribution?.extent_distribution}
                                                />
                                              );
                                            }
                                          }
                                          return <></>;
                                        }
                                      )}
                                  </Stack>
                                </TabPanel>
                              </React.Fragment>
                            )
                          )}
                          {eventGroupData?.details?.impacted_areas &&
                            eventGroupData?.details?.impacted_areas?.map(
                              (impacted_area, impacted_area_index, arr) => {
                                if (
                                  impacted_area &&
                                  impacted_area.section_name === EVENT_SOURCE_IMPACT
                                ) {
                                  return (
                                    <TabPanel
                                      value={EVENT_SOURCE_IMPACT}
                                      key={impacted_area_index}
                                      sx={(theme) => ({ padding: 0 })}
                                    >
                                      <Stack direction="column" gap={6}>
                                        <EventDetailCards
                                          sx={(theme) => ({
                                            width: "100%",
                                            boxShadow: "0px 2px 4px 0px #00000014",
                                          })}
                                          style={{ overflowY: "auto" }}
                                          cardContentStyles={{
                                            padding: 0,
                                          }}
                                        >
                                          <Stack direction="column" ml={2} gap={2}>
                                            <Typography
                                              sx={(theme) => ({
                                                fontSize: theme.typography.pxToRem(14),
                                              })}
                                            >
                                              Total
                                            </Typography>
                                            <Typography
                                              sx={(theme) => ({
                                                fontSize: theme.typography.pxToRem(20),
                                                fontWeight: typography.font_weight.mediumx,
                                              })}
                                            >
                                              {impacted_area.total_count}
                                            </Typography>
                                          </Stack>
                                          {impacted_area && impacted_area.total_count > 0 ? (
                                            <EventsDistributionChart
                                              chart_data={impacted_area}
                                              chart_name={EVENT_SOURCE_IMPACT}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </EventDetailCards>
                                      </Stack>
                                    </TabPanel>
                                  );
                                } else {
                                  return <></>;
                                }
                              }
                            )}
                        </TabContext>
                      </Box>
                    </EventDetailCards>
                  </Stack>
                ) : null}
              </Box>
            </Box>
            <EventDetailCards
              sx={(theme) => ({ width: "100%", boxShadow: "0px 2px 4px 0px #00000014" })}
              style={{ padding: "16px 20px" }}
              title={eventGroupData?.type !== EVENT_TYPE_OUTAGE ? `Error Logs` : `Outage History`}
              image={`event_error_details`}
            >
              <Box
                mt={7}
                mb={5}
                height="auto"
                sx={{
                  marginTop: 0,
                }}
              >
                {eventGroupData?.events?.length > 0 ? (
                  <EventPayloadTable
                    events={eventGroupData?.events}
                    type={eventGroupData?.type}
                    sub_type={eventGroupData?.sub_type}
                  />
                ) : (
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.mediumx,
                      color: "var(--steel-gray)",
                    })}
                  >
                    {`No ${
                      eventGroupData?.type !== EVENT_TYPE_OUTAGE ? `Error Logs` : `Outage History`
                    } available`}
                  </Typography>
                )}
              </Box>
            </EventDetailCards>
          </Stack>
          {!isSmallScreen ? renderEventsDetailsBlock() : null}
        </Stack>
      </Box>
    </L3ContainerMain>
  );
};

export default EventGroupCollaborateScreen;
