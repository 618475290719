export const MASTER_TENANT = "outoftheblue";
export const SELECTED_TENANT = "SELECTED_TENANT";

export const TENANT_CONFIG_REQUEST = "TENANT/CONFIG/REQUEST";
export const TENANT_CONFIG_REQUEST_SUCCESS = "TENANT/CONFIG/REQUEST/SUCCESS";
export const TENANT_CONFIG_REQUEST_FAILED = "TENANT/CONFIG/REQUEST/FAIL";

export const TENANT_UPDATE_GLOBAL_FILTER = "TENANT/UPDATE/GLOABL/FILTER";
export const TENANT_DELETE_GLOBAL_FILTER = "TENANT/DELETE/GLOBAL/FILTER";

export const TENANT_UPDATE_DEMO_MODE = "TENANT/UPDATE/DEMO/MODE";
