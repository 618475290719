import axios from "axios";
import {
  RESOURCE_TYPE_EVENT_GROUP,
  RESOURCE_TYPE_GROUP,
  RESOURCE_TYPE_INSIGHT,
} from "../constants/commonConstants";
import {
  GROUP_INSIGHT_OPEN_REQUEST,
  GROUP_INSIGHT_OPEN_SUCCESS,
  GROUP_INSIGHT_OPEN_FAIL,
  GROUP_INSIGHT_IMPORTANT_REQUEST,
  GROUP_INSIGHT_IMPORTANT_SUCCESS,
  GROUP_INSIGHT_IMPORTANT_FAIL,
  GROUP_INSIGHT_ARCHIVE_REQUEST,
  GROUP_INSIGHT_ARCHIVE_SUCCESS,
  GROUP_INSIGHT_ARCHIVE_FAIL,
  GROUP_INSIGHT_LIST_REQUEST,
  GROUP_INSIGHT_LIST_SUCCESS,
  GROUP_INSIGHT_LIST_FAIL,
  GROUP_INSIGHT_FLAG_REQUEST,
  GROUP_INSIGHT_FLAG_SUCCESS,
  GROUP_INSIGHT_FLAG_FAIL,
  GROUP_INSIGHT_RESOLVE_FAIL,
  GROUP_INSIGHT_RESOLVE_REQUEST,
  GROUP_INSIGHT_RESOLVE_SUCCESS,
  GROUP_INSIGHT_SNOOZE_REQUEST,
  GROUP_INSIGHT_SNOOZE_SUCCESS,
  GROUP_INSIGHT_SNOOZE_FAIL,
} from "../constants/groupInsightConstans";
import {
  TEAM_INSIGHT_ACTION_FAILED,
  TEAM_INSIGHT_ACTION_REQUESTED,
} from "../constants/teamConstants";
import { OPEN, FLAGGED, RESOLVED } from "../constants/userConstants";

const url = process.env.REACT_APP_BASE_URL.concat("insights");
let collated_insights_groups = [];
let collated_insights_groups_charts = [];
let prevMenuItem = null;
let prev_start_time = null;
let pre_end_time = null;
let prev_dim_name = null;
let prev_dim_val = null;
let nonUserStates = [OPEN, FLAGGED, RESOLVED];

export const resetGroupInsights = () => async (dispatch) => {
  collated_insights_groups = [];
  dispatch({
    type: GROUP_INSIGHT_LIST_REQUEST,
    payload: collated_insights_groups,
  });
};

export const listGroupInsights = (params) => async (dispatch) => {
  const { state, start_time, end_time, dim_name, dim_val } = params;

  try {
    if (prevMenuItem !== state) {
      prevMenuItem = state;
      collated_insights_groups = [];
    }
    if (pre_end_time !== end_time || prev_start_time !== start_time) {
      pre_end_time = end_time;
      prev_start_time = start_time;
      collated_insights_groups = [];
    }

    if (prev_dim_name !== dim_name || prev_dim_val !== dim_val) {
      prev_dim_name = dim_name;
      prev_dim_val = dim_val;
      collated_insights_groups = [];
    }

    dispatch({
      type: GROUP_INSIGHT_LIST_REQUEST,
      payload: collated_insights_groups,
    });

    const { data } = await axios.get(`${url}/groups`, {
      params: new URLSearchParams(params),
    });
    collated_insights_groups = [...collated_insights_groups, ...data.groups];
    dispatch({
      type: GROUP_INSIGHT_LIST_SUCCESS,
      payload: collated_insights_groups,
    });
    return data;
  } catch (error) {
    dispatch({
      type: GROUP_INSIGHT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateGroupOpen =
  (insightList, groupId, state) => async (dispatch) => {
    try {
      dispatch({ type: GROUP_INSIGHT_OPEN_REQUEST });
      const { data } = await axios.post(`${url}/groups/${groupId}/user_state`, {
        ...state,
      });
      dispatch({
        type: GROUP_INSIGHT_OPEN_SUCCESS,
        payload: data,
      });

      collated_insights_groups = collated_insights_groups.filter(
        (group) => group.group_id !== data.group_id
      );
      dispatch({
        type: GROUP_INSIGHT_LIST_SUCCESS,
        payload: collated_insights_groups,
      });
      return data.success;
    } catch (error) {
      dispatch({
        type: GROUP_INSIGHT_OPEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      return error.response.data.success;
    }
  };

export const updateGroupImportant =
  (insightList, groupId, state) => async (dispatch) => {
    try {
      dispatch({ type: GROUP_INSIGHT_IMPORTANT_REQUEST });
      const { data } = await axios.post(`${url}/groups/${groupId}/user_state`, {
        ...state,
      });
      setTimeout(() => {
        dispatch({
          type: GROUP_INSIGHT_IMPORTANT_SUCCESS,
          payload: data,
        });
      }, 500);

      collated_insights_groups = collated_insights_groups.filter(
        (group) => group.group_id !== data.group_id
      );
      dispatch({
        type: GROUP_INSIGHT_LIST_SUCCESS,
        payload: collated_insights_groups,
      });
      return data.success;
    } catch (error) {
      dispatch({
        type: GROUP_INSIGHT_IMPORTANT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      return error.response.data.success;
    }
  };

export const updateGroupArchive =
  (insightList, groupId, state) => async (dispatch) => {
    try {
      dispatch({ type: GROUP_INSIGHT_ARCHIVE_REQUEST });
      const { data } = await axios.post(`${url}/groups/${groupId}/user_state`, {
        ...state,
      });
      setTimeout(() => {
        dispatch({
          type: GROUP_INSIGHT_ARCHIVE_SUCCESS,
          payload: data,
        });
      }, 500);
      collated_insights_groups = collated_insights_groups.filter(
        (group) => group.group_id !== data.group_id
      );
      dispatch({
        type: GROUP_INSIGHT_LIST_SUCCESS,
        payload: collated_insights_groups,
      });
      return data.success;
    } catch (error) {
      dispatch({
        type: GROUP_INSIGHT_ARCHIVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      return error.response.data.success;
    }
  };

export const getGroupInsightsCharts = (group_id, params) => async () => {
  try {
    const { data } = await axios.get(`${url}/groups/${group_id}/metrics`, {
      params: new URLSearchParams(params),
    });
    return data;
  } catch (error) {
    return error.response.data.success;
  }
};

export const updateGroupSnooze =
  (insightList, groupId, state, time) => async (dispatch) => {
    try {
      dispatch({ type: GROUP_INSIGHT_SNOOZE_REQUEST });
      const { data } = await axios.post(`${url}/groups/${groupId}/user_state`, {
        ...state,
        state_data: {
          snooze_time: time,
        },
      });
      setTimeout(() => {
        dispatch({
          type: GROUP_INSIGHT_SNOOZE_SUCCESS,
          payload: data,
        });
      }, 500);
      collated_insights_groups = collated_insights_groups.filter(
        (group) => group.group_id !== data.group_id
      );
      dispatch({
        type: GROUP_INSIGHT_LIST_SUCCESS,
        payload: collated_insights_groups,
      });
      return data.success;
    } catch (error) {
      dispatch({
        type: GROUP_INSIGHT_SNOOZE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      return error.response.data.success;
    }
  };

export const getInsightById = (insightId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${url}/${insightId}`);
    return data;
  } catch (error) {
    return error.response.data.success;
  }
};

export const getInsightByIds = (insightIds) => async (dispatch) => {
  try {
    const response = await Promise.all(
      insightIds.map(async (insightId) => {
        return { response: await axios.get(`${url}/${insightId}`) };
      })
    );
    return response;
  } catch (err) { }
};

export const getL3DataByResourceType =
  (resourceType, resourceId, queryParams) => async (dispatch) => {
    try {
      let result = null;
      switch (resourceType) {
        case RESOURCE_TYPE_GROUP:
          result = await axios.get(`${url}/groups/${resourceId}`,  {
            params: new URLSearchParams(queryParams),
          });
          return result?.data;
        case RESOURCE_TYPE_INSIGHT:
          result = await axios.get(`${url}/${resourceId}`);
          return result?.data;
      }
    } catch (error) {
      throw error;
    }
  };

export const updateGroupStateForTeam =
  (state, groupList) => async (dispatch) => {
    try {
      dispatch({
        type: TEAM_INSIGHT_ACTION_REQUESTED,
        selectedInsights: groupList,
      });
      const { data } = await axios.post(`${url}/groups/team_states`, {
        ...state,
        groups: [...groupList],
      });

      collated_insights_groups = collated_insights_groups.filter((group) => {
        return !(
          data?.success &&
          nonUserStates.includes(state?.old_state) &&
          groupList.includes(group.group_id)
        );
      });
      dispatch({
        type: GROUP_INSIGHT_LIST_SUCCESS,
        payload: collated_insights_groups,
      });
      return data.success;
    } catch (error) {
      dispatch({
        type: TEAM_INSIGHT_ACTION_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      return error.response.data.success;
    }
  };

export const getFeedback = (resourceType, resourceId) => async (dispatch) => {
  try {
    // Switch case: INSIGHT, INSIGHT_GROUP, EVENT_GROUP
    switch (resourceType) {
      case RESOURCE_TYPE_GROUP:
        const { data: groupData } = await axios.get(`${url}/groups/${resourceId}/feedback`);
        return groupData;
      case RESOURCE_TYPE_INSIGHT:
        const { data: insightData } = await axios.get(`${url}/${resourceId}/feedback`);
        return insightData;
      case RESOURCE_TYPE_EVENT_GROUP:
        const { data: eventGroupData } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/groups/${resourceId}/feedback`);
        return eventGroupData;
      default:
        return null;
    }
  } catch (error) {
    throw error.response;
  }
};

export const postFeedback =
  (resourceType, resourceId, feedback_ishelpful, feedback_comment = null) =>
    async (dispatch) => {
      try {
        const commentOnly = feedback_comment && feedback_ishelpful === null;
        const isHelpfulOnly = feedback_ishelpful !== null && !feedback_comment;

        const payload = isHelpfulOnly
          ? { feedback_ishelpful: feedback_ishelpful }
          : commentOnly
            ? { feedback_comment: feedback_comment }
            : {};
        if (resourceType === RESOURCE_TYPE_GROUP) {
          const { data } = await axios.post(
            `${url}/groups/${resourceId}/feedback`,
            payload
          );
          return data;
        } else if (resourceType === RESOURCE_TYPE_INSIGHT) {
          const { data } = await axios.post(
            `${url}/${resourceId}/feedback`,
            payload
          );
          return data;
        } else if (resourceType === RESOURCE_TYPE_EVENT_GROUP) {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/events/groups/${resourceId}/feedback`,
            payload
          );
          return data;
        }
      } catch (error) {
        throw error.response;
      }
    };
