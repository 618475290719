import { createTheme } from "@mui/material/styles";

export const colors = {
  status: {
    SUCCESSFUL: "#43A45C",
    FAILED: "#E88A8A",
    IN_PROGRESS: "#EFB515",
    NOT_STARTED: "#EFB515",
    PENDING: "#E88A8A",
    CANCELLED: "#E88A8A",
  },
  dimensions_breakup: ["#243FAC", "#4359b0", "#245AE3", "#7082DC", "#8698E3", "#D2DBF2"],
  events_dimensions_color_gradients: [
    { line: "#AEA6FA", gradient: ["#FFFFFF", "#AEA6FA"] },
    { line: "#1DA5E2", gradient: ["#FFFFFF", "#1DA5E2"] },
    { line: "#F6C84A", gradient: ["#FFFFFF", "#F6C84A"] },
    { line: "#42BE8B", gradient: ["#FFFFFF", "#42BE8B"] },
    { line: "#E25D33", gradient: ["#FFFFFF", "#E25D33"] },
    { line: "#7B86C6", gradient: ["#FFFFFF", "#7B86C6"] },
  ],
  gray: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    325: "#9DAFD9",
    350: "#D6D6D6",
    375: "#6B6D77",
    400: "#a1a1aa",
    450: "#757575",
    500: "#71717a",
    550: "#4C5B66",
    575: "#474747",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a",
    900: "#18181b",
    1000: "#0000008a",
  },
  blue: {
    50: "#eff6ff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#3b82f6",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a",
    925: "#112785",
    1000: "#02096A",
  },
  green: {
    50: "#f0fdf4",
    100: "#dcfce7",
    150: "#E2F6E7",
    200: "#bbf7d0",
    300: "#86efac",
    400: "#4ade80",
    450: "#68C07E",
    500: "#22c55e",
    600: "#16a34a",
    700: "#15803d",
    800: "#166534",
    900: "#14532d",
    1000: "#68D1BF",
  },
  orange: {
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fdba74",
    400: "#fb923c",
    500: "#f97316",
    600: "#ea580c",
    700: "#c2410c",
    800: "#9a3412",
    900: "#7c2d12",
  },
  pink: {
    50: "#fdf2f8",
    100: "#fce7f3",
    200: "#fbcfe8",
    300: "#f9a8d4",
    400: "#f472b6",
    500: "#ec4899",
    600: "#db2777",
    700: "#be185d",
    800: "#9d174d",
    900: "#831843",
  },
  red: {
    50: "#fef2f2",
    100: "#fee2e2",
    150: "#f7d1d1",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    450: "#D95A5A",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
  },
  topMetricColorMap: {
    0: "#7B86C6",
    1: "#1DA5E2",
    2: "#1561D3",
    3: "#169AD3",
  },
  metricColorMap: {
    0: "#AEA6FA",
    1: "#1DA5E2",
    2: "#F6C84A",
    3: "#42BE8B",
    4: "#E25D33",
    5: "#7B86C6",
    6: "#40B1B8",
    7: "#5DE0CF",
    8: "#1B2DD4",
    9: "#7DA7CA",
    10: "#315B7E",
    11: "#6D8383",
    12: "#203737",
    13: "#DCA978",
    14: "#814CA7",
  },
  positive_accent_colorway: [
    "#2A5760",
    "#2D818D",
    "#40B1B8",
    "#87D6D9",
    "#B8E8E9",
  ],
  hotspot_positive_accent_colorway: [
    "#338648",
    "#61A472",
    "#71AE80",
    "#90C19B",
    "#AFD5B7",
    "#BEDFC5"
  ],
  negative_accent_colorway: [
    "#7C354D",
    "#B14D6A",
    "#CF7F92",
    "#E7BAC2",
    "#F2D9DE",
  ],
  hotspot_negative_accent_colorway: [
    "#933B3B",
    "#A75C5C",
    "#B16D6D",
    "#CE9E9E",
    "#D8AFAF",
    "#E2BFBF"
  ],
  mariner: "#245AE6",
  comet: "#5C6178",
  cadet_gray: "#9EA2B6",
  disabled: "#B1B4B7",
  white: "#FFFFFF",
  white_primary: "#EFF2FA",
  white_secondary: "#F6F9FE",
  disabled: "#B1B4B7",
  turquoise: "#5DE0CF",
  azure: "#5183F4",
  blue_bell: "#8C9AD3",
  crisis_red: "#C02323",
  positive_green: "#84D197",
  negative_red: "#E88A8A",
  SEVERITY: {
    GREEN: {
      low: "#68C07E",
      medium: "#43A45C",
      high: "#338648",
    },
    RED: {
      low: "#E88A8A",
      medium: "#E55F5F",
      high: "#C43E3E",
    },
  },
  PIPELINE_SCHEDULE: {
    hourly: "#5C6178",
    daily: "#245AE6",
    weekly: "#8A919C",
    monthly: "#7597F0",
    quarterly: "#92ADF3",
  },
  BUSINESS_HEALTH: {
    GOOD: "#40B1B8",
    NEUTRAL: "#FFC700",
    SLOW: "#CF7F92"
  },
  chart_tooltip: {
    gray : "#808080",
    blue : "#1e46ef",
  },
  title: "#5C6178",
  background_color: "#e5ecfd",
  offwhite: "#f4f8fb",
  header_bg: "rgba(17, 39, 133, 1)",
  emoji_default: "#777777",
  emoji_selected: "#536CE4",
  emoji_selected_bg: "#dbeafe",
  popper_font_color: "#545C7E",
  popper_footer_bgcolor: "#245AE6",
};
export const typography = {
  font_size: {
    xs: 12,
    sm: 14,
    base: 16,
    lg: 18,
    xl: 20,
    "2xl": 24,
  },
  font_weight: {
    extraLight: 200,
    light: 300,
    regular: 400,
    mediumx: 500,
    semiBold: 600,
    bold: 700,
    bolder: 800,
    black: 900,
  },
};
export const color_continuous_scale = {
  Greens: [
    ["0", "#338648"],
    ["0.1", "#429056"],
    ["0.2", "#529A64"],
    ["0.3", "#61A472"],
    ["0.4", "#71AE80"],
    ["0.5", "#80B78D"],
    ["0.6", "#90C19B"],
    ["0.7", "#9FCBA9"],
    ["0.8", "#AFD5B7"],
    ["1", "#BEDFC5"]
  ],
  Reds: [
    ["0", "#892B2B"],
    ["0.1", "#933B3B"],
    ["0.2", "#9D4C4C"],
    ["0.3", "#A75C5C"],
    ["0.4", "#B16D6D"],
    ["0.5", "#BA7D7D"],
    ["0.6", "#C48E8E"],
    ["0.7", "#CE9E9E"],
    ["0.8", "#D8AFAF"],
    ["1", "#E2BFBF"]
  ],
  RdYlGn: [
    ["0", "#892B2B"],
    ["0.1", "#9F5050"],
    ["0.2", "#B67575"],
    ["0.3", "#CC9A9A"],
    ["0.4", "#E2BFBF"],
    ["0.5", "#BEDFC5"],
    ["0.6", "#9BC9A6"],
    ["0.7", "#79B387"],
    ["0.8", "#569C67"],
    ["1", "#338648"]
  ],
  RdYlGn_r: [
    ["0", "#338648"],
    ["0.1", "#569C67"],
    ["0.2", "#79B387"],
    ["0.3", "#9BC9A6"],
    ["0.4", "#BEDFC5"],
    ["0.5", "#E2BFBF"],
    ["0.6", "#CC9A9A"],
    ["0.7", "#B67575"],
    ["0.8", "#9F5050"],
    ["1", "#892B2B"],
  ],
};

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      sm: 0, // Mobile small
      mm: 321, // Mobile Medium
      lm: 376, // Mobile large
      st: 481, // Small Tablet
      tm: 601, // Medium Tablet -> Named tm because it was colliding with mt (margin-top) attribute of mui
      lt: 769, // Large Tablet
      sl: 1025, // Small laptop
      mls: 1367, // medium laptop -> named mls (medium-laptop-screen) because it was colliding with ml (margin-left) attribute of mui
      ll: 1441, // Large laptop
      xll: 1536, // extra large laptop
    },
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: colors.mariner,
    },
    secondary: {
      main: colors.comet,
    },
  },
  spacing: 4,
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        grouped: ({ ownerState, theme }) => ({
          "&:not(.sideButton)": {
            ...(ownerState.size === "xlarge" && {
              padding: theme.spacing(3, 6),
            }),
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textAlign: "center",
        }
      }
    },
    MuiTableCell:{
      styleOverrides:{
        root:{
          color: "unset"
        }
      }
    },
    MuiCard:{
      styleOverrides:{
        root:{
          boxShadow: "0px 2px 15px 0px #11278529",
        }
      }
    }
  },
});
