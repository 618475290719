import { Box, CardHeader, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useAxios } from "../../../hooks/useAxios";
import { HTTP_STATUS } from "../../../shared/enums/http-status";
import { colors } from "../../../shared/theme-constants";
import MultiSelect from "../../common/mui-wrapper-components/Select/MultiSelect";
import { Loading } from "../../common/styled-components";
import { StyledCardV2 } from "../../common/styled-components/Card.styled";

const HotspotDimensionBreakupChart = ({ rootCauseDetails }) => {
  const [loading, setLoading] = useState(true);
  const [dataCategories, setDataCategories] = useState([]);
  const [expectedDataValuesArray, setExpectedDataValuesArray] = useState([]);
  const [actualDataValuesArray, setActualDataValuesArray] = useState([]);

  useEffect(() => {
    if (rootCauseDetails && rootCauseDetails.length > 0) {
      const rootcause_details = rootCauseDetails;
      setDataCategories(rootcause_details.map((item) => item.dimension_value));
      setExpectedDataValuesArray(rootcause_details.map((item) => -item.expected_dimension_value));
      setActualDataValuesArray(rootcause_details.map((item) => item.actual_dimension_value));
      setLoading(false);
    }
    // add clean up
    return () => {
      setDataCategories([]);
      setExpectedDataValuesArray([]);
      setActualDataValuesArray([]);
      setLoading(true);
    };
  }, [rootCauseDetails]);
  const generateChartOptions = () => {
    const expected_data_abs_max = Math.max(...expectedDataValuesArray?.map(Math.abs));
    const actual_data_abs_max = Math.max(...actualDataValuesArray?.map(Math.abs));
    const abs_max = Math.max(expected_data_abs_max, actual_data_abs_max);
    return {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },
      xAxis:
        {
          opposite: false,
          categories: dataCategories,
          reversed: true,
          labels: {
            step: 1,
          },
        },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          enabled: true,
          formatter: function () {
            return Math.abs(this.value);
          },
        },
        min: -(abs_max + 10),
        max: abs_max + 10,
      },

      plotOptions: {
        series: {
          pointWidth: 35, // fixed width in pixels for each bar
          stacking: "normal",
          borderRadius: "20%",
        },
      },

      tooltip: {
        formatter: function () {
          // Round to the nearest integer
          const intValue = Math.round(Math.abs(this.y));
          return `<b>${this.series.name}, ${this.point.category}</b><br/>Value: ${intValue}`;
        },
      },

      series: [
        {
          color: "var(--feed-background-blue)",
          name: "Exptected Value",
          data: [...expectedDataValuesArray],
        },
        {
          color: "var(--resolution-blue)",
          name: "Actual Value",
          data: [...actualDataValuesArray],
          // Enable dataLabels for this series only
          dataLabels: {
            style: {
              color: colors.gray[575],
              fontSize: ".75em",
            },
            enabled: true,
            // Example: show the 'pct' property or do a custom calculation
            formatter: function () {
              // Suppose each point has a 'pct' property indicating a % difference
              // If you just want "this.y + '%'", adapt as needed.
              return rootCauseDetails?.find((item) => item?.dimension_value === this.x)
                ?.formatted_dimension_value_movement_pct;
            },
            inside: false,
            x: 50,
            align: "right",
          },
        },
      ],
    };
  };
  if (loading) {
    return <Loading />;
  }
  return <HighchartsReact highcharts={Highcharts} options={generateChartOptions()} />;
};

const EventHotspotChart = ({ event_group }) => {
  // get dimension from tenantGloablFilter
  const globalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const [availableDimensions, setAvailableDimensions] = useState(
    event_group.dimensions.length > 0
      ? event_group.dimensions.filter((item) => item.dimension_name !== globalFilter.dimension_name)
      : []
  );
  const [selectedDimensionName, setSelectedDimensionName] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      dimension: availableDimensions[0],
    }
  );
  const {
    data: eventHotspotData,
    status: eventHotspotStatus,
    error: eventHotspotDataError,
    isError,
  } = useAxios(`events/${event_group.events[0].event_uuid}/rootcause`, {
    params: {
      tenant_id: event_group.tenant_id,
      dimension_names: selectedDimensionName.dimension.dimension_name,
    },
  });

  const handleDimensionChange = (event, newValue) => {
    setSelectedDimensionName({ dimension: newValue });
  };
  if (isError && eventHotspotDataError?.response?.status === HTTP_STATUS.NOT_FOUND) {
    return <></>;
  }

  return (
    <StyledCardV2>
      <Stack direction="column" justifyContent="space-between" sx={{ mb: 6 }} gap={4}>
        <CardHeader
          sx={(theme) => ({ pl: 0, fontSize: theme.typography.pxToRem(16) })}
          titleTypographyProps={{ fontSize: "1.25rem", fontWeight: "bold" }}
          subheaderTypographyProps={{ fontSize: "0.875rem", color: "text.secondary" }}
          title="Root Cause Analysis"
          subheader="Highlights the dimension value that changed the most"
        />
        <Stack direction="row" gap={2} alignItems={"center"}>
          <Typography variant="body2" sx={{ color: colors.gray[575] }}>
            Select Dimension:
          </Typography>
          <MultiSelect
            multiple={false}
            disableClearable
            placeholder="Add Dimension"
            onChange={handleDimensionChange}
            disabled={false}
            value={selectedDimensionName.dimension}
            options={availableDimensions}
            getOptionLabel={(option) => option.dimension_display_name}
            isOptionEqualToValue={(option, value) => option.dimension_name === value.dimension_name}
          />
        </Stack>

        {eventHotspotStatus === "loading" ? (
          <Box>
            <Loading />
          </Box>
        ) : (
          <HotspotDimensionBreakupChart
            rootCauseDetails={eventHotspotData?.data?.rootcause_details}
          />
        )}
      </Stack>
    </StyledCardV2>
  );
};

export default EventHotspotChart;
