import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { init, browserTracingIntegration, replayIntegration } from "@sentry/react";
import App from "./components/App";
import store from "./store";
import { merge } from "./utils/objects";

function tweakLocationStateOnPageLoad() {
  const usrLocationState = window.history.state?.usr;

  if (usrLocationState?.backgroundLocation) {
    window.history.replaceState(
      merge(window.history.state, { usr: { isOverlay: false } }),
      document.title
    );
  }
}

const hasWindow = typeof window !== "undefined";

if (hasWindow) {
  tweakLocationStateOnPageLoad();
}
if (process.env.REACT_APP_SENTRY_DSN_KEY) {
  const baseUrl = process.env.REACT_APP_BASE_URL?.split("v1")[0];
  // Initiate Sentry
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,     // Allow text capture in recordings.
        blockAllMedia: false,   // Allow images/icons to be visible in recordings.
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [baseUrl],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
