import axios from "axios";
import { SET_DIGEST_SCREEN_TIMESTAMP, TIME_FILTER_INITIATE } from "../constants/filterCriteriaConstants";
import { subDays } from "date-fns";
import { TENANT_CONFIG_REQUEST_SUCCESS, TENANT_UPDATE_GLOBAL_FILTER } from "../constants/tenantConstants";
import { isEnabled } from "../utils/featureFlags";
import { SAAS_FILTERING_FEATURE_TOGGLE } from "../constants/featureFlagConstants";
import { isEmpty } from "../utils/is";

const url = process.env.REACT_APP_BASE_URL.concat("tenants");
export const getTenantInfo = (tenantId, queryParams) => async (dispatch) => {
    try {
        // form queryparms object
        let params = new URLSearchParams({ tenant_id: tenantId });
        // Dim name and values are optional params,
        // If not sent api will respond back with the data set for default filters from backend
        if (queryParams?.dimension_name && queryParams?.dimension_value) {
            params.append("dim_name", queryParams?.dimension_name);
            params.append("dim_val", queryParams?.dimension_value);
        } else if (queryParams?.dimension_name === null && queryParams?.dimension_value === null) {
            params.append("dim_name", null);
            params.append("dim_val", null);
        }

        const { data } = await axios.get(`${url}/info`, { params });
        dispatch({
            type: SET_DIGEST_SCREEN_TIMESTAMP,
            payload: {
                start_time: data.last_digest_ts ? {
                    "day": data.last_digest_ts.day,
                    "week": data.last_digest_ts.day,
                    "month": data.last_digest_ts.month,
                    "quarter": data.last_digest_ts.quarter
                } : {
                    "day": new Date().toISOString(),
                    "week": new Date().toISOString(),
                    "month": new Date().toISOString(),
                    "quarter": new Date().toISOString()
                }
            },
        });
        // dispatch tenant configs
        dispatch({
            type: TENANT_CONFIG_REQUEST_SUCCESS,
            payload: {
                is_forecast_enabled: data.is_forecast_enabled,
                currency: data?.currency,
                currency_symbol: data?.currency_symbol,
                timezone: data?.timezone,
            }
        });
        dispatch({
            type: TIME_FILTER_INITIATE,
            payload: {
                start_time: data?.last_insight_ts ? subDays(new Date(data.last_insight_ts), 30).toISOString() : subDays(new Date(), 30).toISOString(),
                end_time: data?.last_insight_ts ? data?.last_insight_ts : new Date().toISOString(),
                type: "initial",
                history: []
            },
        });
        return;
    } catch (error) {
        throw error;
    }
};

export const getTenantGlobalFilters = (tenantId) => async (dispatch, state) => {
    // Get the state of the application
    const valueInLocalStorage = window.localStorage.getItem("currentAppliedGlobalFilters");
    let parsedValue = JSON.parse(valueInLocalStorage);

    // Check If any query params present in the URL to enforce those values on the Users view
    const urlParams = new URLSearchParams(window.location.search);
    const dimension_name = isEmpty(urlParams.get('dim_name')) ? null : decodeURIComponent(urlParams.get('dim_name'));
    const dimension_value = isEmpty(urlParams.get('dim_val')) ? null : decodeURIComponent(urlParams.get('dim_val'));
    try {
        if (!isEnabled(SAAS_FILTERING_FEATURE_TOGGLE)) {
            window.localStorage.removeItem("currentAppliedGlobalFilters");
            dispatch({ type: TENANT_UPDATE_GLOBAL_FILTER, payload: { dimension_name: null, dimension_value: null } });
        } else if (dimension_name && dimension_value) {
            const params = new URLSearchParams({ tenant_id: tenantId });
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}feed/filters`, {
                params,
            });
            let filterToSetFromQueryparams = data?.data?.find((filter) => {
                return filter.dimension_name === dimension_name && filter.dimension_value === dimension_value;
            });
            // sanity check
            if (filterToSetFromQueryparams) {
                // First set the local storage
                window.localStorage.setItem("currentAppliedGlobalFilters", JSON.stringify(filterToSetFromQueryparams));
                // Then Dispatch the same to the redux store
                dispatch({ type: TENANT_UPDATE_GLOBAL_FILTER, payload: filterToSetFromQueryparams });
            }
            return;
        } else {

            // If no values present in Query params as well as local storage
            // Get the filters from backend and set the one which has is_default = true
            const params = new URLSearchParams({ tenant_id: tenantId });

            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}feed/filters`, {
                params,
            });
            let defaultGlobalFilter = data?.data?.find((filter) => filter.is_default);
            // sanity check
            if (defaultGlobalFilter) {
                // First set the local storage
                window.localStorage.setItem("currentAppliedGlobalFilters", JSON.stringify(defaultGlobalFilter));
                // Then Dispatch the same to the redux store
                dispatch({ type: TENANT_UPDATE_GLOBAL_FILTER, payload: defaultGlobalFilter });
            }
        }
    } catch (error) {
        throw error;
    }

};