import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS_NEW } from "../constants/commonConstants";
import { errorMessage } from "../shared/intl/error-messages";
import ScreenError from "./common/error-components/ScreenError";
import HomeLayout from "./HomeLayout";
import OnboardingLayout from "./OnboardingLayout";
import { LoaderInCenter } from "./common/styled-components";

const isDevelopment = process.env.NODE_ENV === "development";

const FallbackComponent = ({ error }) => {
  return (
    <ScreenError title="" message={errorMessage.UNEXPECTED_ERROR} showButtons>
      {isDevelopment ? (
        <Container maxWidth="md" mx="auto">
          <pre style={{ whiteSpace: "pre-wrap" }}>{error.message}</pre>
        </Container>
      ) : null}
    </ScreenError>
  );
};

// This is the master component just beneath App.jsx
// The main purpose of this layout is understand if onboarding of user is complete
// If so trigger onboarding flow else trigger
const AppLayout = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const userObject = useSelector((state) => state.userLogin);
  const [onboardingFlow, setOnboardingFlow] = useState(false);
  const navigate = useNavigate();

  const initializeDevRev = (sessionToken) => {
    // Below piece of code integrates devRev plug widget into our system.
    if (sessionToken) {
      window.plugSDK.init({
        app_id: process.env.REACT_APP_DEV_REV_APP_ID,
        session_token: sessionToken // Include the session token in the initialization
      });
    } else {
      window.plugSDK.init({
        app_id: process.env.REACT_APP_DEV_REV_APP_ID
      });
    }
  };

  const generateSessionToken = async (user) => {
    try {
      const response = await fetch('https://api.devrev.ai/auth-tokens.create', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': process.env.REACT_APP_DEV_REV_PUBLIC_AUTH_TOKEN,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rev_info: {
            "user_ref": user?.email,
            "user_traits": {
              "email": user?.email,
              "display_name": `${user?.given_name} ${user?.family_name}`
            }
          }
        })
      });
      if (!response.ok) {
        throw new Error('Failed to generate session token');
      }
      const data = await response.json();
      return data.access_token; // Return the session token for use later
    } catch (error) {
      // Nothing to be done here.
    }
  };

  useEffect(() => {
    if (userObject.user?.email) {
      if (process.env.REACT_APP_DEV_REV_APP_ID && process.env.REACT_APP_DEV_REV_PUBLIC_AUTH_TOKEN) {
        const initialize = async () => {
          try {
            const sessionToken = await generateSessionToken(userObject.user);
            if (sessionToken) {
              initializeDevRev(sessionToken);
            }
          } catch (error) { }
        };
        initialize();
      }
    } else {
      if (process.env.REACT_APP_DEV_REV_APP_ID && process.env.REACT_APP_DEV_REV_PUBLIC_AUTH_TOKEN) {
        initializeDevRev();
      }
    }
  }, []);

  useEffect(() => {
    // We Will Add the Flow which navigates either to home page or Onboarding
    // In side Onboarding we will then handle the drop Off points and then navigate accordingly

    let url_path = location.pathname.split('/');

    if (userObject?.user.show_onboarding) {
      setLoading(false);
      setOnboardingFlow(userObject?.user?.show_onboarding); // Placeholder for navigate
      if (userObject?.user_status === STATUS_NEW) {
        navigate(userObject?.tenant_status === STATUS_NEW ? `/onboard/create_tenant` : `/onboard/join_workspace`);
      } else {
        setOnboardingFlow(false);
        // Check session storage for demo_mode to be "true", if yes, redirect to /events
        const demoModeValue = window.sessionStorage.getItem("demo_mode");

        if (demoModeValue && demoModeValue === "true") {
          navigate("/events");
        } else {
          navigate("/");
        }
      }
    } else if (url_path.includes("onboard")) {
      setLoading(false);
      navigate("/");
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoaderInCenter />;
  }
  if (onboardingFlow) {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <OnboardingLayout />
      </ErrorBoundary>
    );
  }
  return <HomeLayout />;
};

export default AppLayout;
