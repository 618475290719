import { Box, Stack, Tooltip, Typography } from "@mui/material";
import pluralize from "pluralize";
import React from "react";
import {
  BROWSER_EVENT_SUB_TYPES,
  EVENT_SUB_TYPE_ASSET,
  EVENT_SUB_TYPE_BEACON,
  EVENT_SUB_TYPE_EXPERIRENCE,
  EVENT_SUB_TYPE_JAVASCRIPT,
  EVENT_SUB_TYPE_NETWORK,
  EVENT_TYPE_ERROR,
  EVENT_TYPE_OUTAGE,
} from "../../../constants/eventConstants";
import { typography } from "../../../shared/theme-constants";
import { titleCase } from "../../../utils/stringUtils";
import { ColoredBoldMetric } from "../../common/styled-components/insight-summay/Title.styled";
import { deserializeToJSX, parseMarkdown } from "../../../services/markdown";

const generateOutageTitle = (providerDisplayName, severity) => {
  switch (severity) {
    case "High":
      return `${providerDisplayName} Major Incident`;
    case "Medium":
      return `${providerDisplayName} Partial Disruption`;
    case "Low":
      return `${providerDisplayName} Scheduled Maintenance`;
    default:
      return `${providerDisplayName} Incident`;
  }
};

function createSerializer(accent) {
  return {
    bold: ({ value }) => (
      <ColoredBoldMetric accent="negative">{value}</ColoredBoldMetric>
    ),
  };
}

function EventGroupCardHeader({
  providerLogo,
  eventCategory,
  type,
  sub_type,
  numberOfEvents,
  providerDisplayName,
  description,
  isDescriptionSectionEnabled = true,
  severity,
  affected_components,
  title,
  summary,
}) {
  const getEventTitle = () => {
    if (type === EVENT_TYPE_OUTAGE) {
      return generateOutageTitle(providerDisplayName, severity);
    } else if (type === EVENT_TYPE_ERROR) {
      const parsedTitle = title ? deserializeToJSX(parseMarkdown(title), createSerializer()) : "";
      switch (sub_type) {
        // Added repeatitive code as we wanted to be super safe
        case EVENT_SUB_TYPE_BEACON:
          return parsedTitle;
        case EVENT_SUB_TYPE_JAVASCRIPT:
          return parsedTitle;
        case EVENT_SUB_TYPE_NETWORK:
          return parsedTitle;
        case EVENT_SUB_TYPE_EXPERIRENCE:
          return parsedTitle;
        case EVENT_SUB_TYPE_ASSET:
          return parsedTitle;
        default:
          return `${numberOfEvents || ""} ${eventCategory} ${pluralize(
            titleCase(type || ""),
            numberOfEvents
          )}`;
      }
    }
  };

  const getEventSummary = () => {
    if (type === EVENT_TYPE_OUTAGE) {
      if (affected_components?.length > 0) {
        return (
          <span>
            <span style={{ fontWeight: 450 }}>Affected Components:</span>{" "}
            {affected_components?.slice(0, 3).join(", ")}
          </span>
        );
      } else {
        return "";
      }
    } else if ((type = EVENT_TYPE_ERROR)) {
      switch (sub_type) {
        // Added repeatitive code as we wanted to be super safe
        case EVENT_SUB_TYPE_BEACON:
          return summary;
        case EVENT_SUB_TYPE_JAVASCRIPT:
          return summary;
        case EVENT_SUB_TYPE_NETWORK:
          return summary;
        case EVENT_SUB_TYPE_EXPERIRENCE:
          return summary;
        case EVENT_SUB_TYPE_ASSET:
          return summary;
        default:
          return description;
      }
    }
  };

  return (
    <Stack
      direction="column"
      gap={1}
      sx={(theme) => ({
        width: "100%",
        borderBottom: "1px solid var(--soft-gray)",
        padding: "18px 16px",
        [theme.breakpoints.down("tm")]: {
          flexDirection: "column",
          gap: "12px",
        },
      })}
    >
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
        width="100%"
        sx={(theme) => ({
          [theme.breakpoints.down("tm")]: {
            width: "100%",
          },
        })}
      >
        <Tooltip title={providerDisplayName} placement="bottom-end">
          <img style={{ width: "22px", height: "22px", objectFit: "contain" }} src={providerLogo} />
        </Tooltip>
        <Tooltip
          title={getEventTitle()}
          placement="bottom-end"
          componentsProps={{
            tooltip: {
              sx: {
                maxHeight: "auto", // Allows the tooltip to expand vertically as needed
                maxWidth: "50vw", // Expands the tooltip horizontally based on screen width
                width: "100%", // Sets width to 100%
                overflow: "hidden",
              },
            },
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: typography.font_size["xl"],
              [theme.breakpoints.down("sl")]: {
                fontSize: typography.font_size.base,
              },
              fontWeight: typography.font_weight.mediumx,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            })}
          >
            {getEventTitle()}
          </Typography>
        </Tooltip>
      </Stack>
      {isDescriptionSectionEnabled && (
        <Box
          sx={(theme) => ({
            width: "70%",
            [theme.breakpoints.down("tm")]: {
              width: "100%",
            },
          })}
        >
          <Tooltip
            title={
              type === EVENT_TYPE_OUTAGE
                ? affected_components?.join(", ") || ""
                : type === EVENT_TYPE_ERROR && BROWSER_EVENT_SUB_TYPES.includes(sub_type)
                ? getEventSummary()
                : description
            }
            componentsProps={{
              tooltip: {
                sx: {
                  maxHeight: "auto", // Allows the tooltip to expand vertically as needed
                  maxWidth: "50vw", // Expands the tooltip horizontally based on screen width
                  width: "100%", // Sets width to 100%
                  overflow: "hidden",
                },
              },
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(12),
                color: "var(--greyish-lavender)",
                [theme.breakpoints.down("tm")]: {
                  fontSize: theme.typography.pxToRem(10),
                },
                fontWeight: 300,
                width: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              })}
            >
              {getEventSummary()}
            </Typography>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
}

export default EventGroupCardHeader;
