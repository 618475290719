import { TENANT_CONFIG_REQUEST, TENANT_CONFIG_REQUEST_FAILED, TENANT_CONFIG_REQUEST_SUCCESS, TENANT_UPDATE_GLOBAL_FILTER, TENANT_DELETE_GLOBAL_FILTER, TENANT_UPDATE_DEMO_MODE } from "../constants/tenantConstants";

let defaultTenantConfigs = {
    is_forecast_enabled: false
};
export const tenantConfigReducer = (state = { ...defaultTenantConfigs }, action) => {
    switch (action.type) {
        case TENANT_CONFIG_REQUEST:
            return { loading: true };
        case TENANT_CONFIG_REQUEST_SUCCESS:
            return {
                loading: false,
                configs: action.payload,
            };
        case TENANT_CONFIG_REQUEST_FAILED:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// This function will fetch the filter value stored in local storage and set it as default state of the application
// 1. this filter value will be used on all the screens to render the pages based on filters applied
const parseFilterFromLocalStorage = () => {
    const valueInLocalStorage = window.localStorage.getItem("currentAppliedGlobalFilters");
    let parsedValue = JSON.parse(valueInLocalStorage);
    if (parsedValue) {
        return parsedValue;
    } else {
        return Object.assign({}, {});
    }
};

export const tenantGlobalFilterReducer = (state = { loading: true, appliedFilter: { ...parseFilterFromLocalStorage() } }, action) => {
    switch (action.type) {
        case TENANT_UPDATE_GLOBAL_FILTER:
            return {
                loading: false,
                appliedFilter: action.payload
            };
        case TENANT_DELETE_GLOBAL_FILTER:
            return {
                loading: false,
                appliedFilter: null
            };
        default:
            return state;
    }
};

export const demoModeFilterReducer = (state = { isDemoMode: false, }, action) => {
    switch (action.type) {
        case TENANT_UPDATE_DEMO_MODE:
            // If action.payload is false, remove the demo mode value from session storage
            if (!action.payload) {
                window.localStorage.setItem("demo_mode", "false");
            } else {
                // If action.payload is true, set the demo mode value in session storage
                window.localStorage.setItem("demo_mode", "true");
            }
            return {
                isDemoMode: action.payload
            };
        default:
            // Check if session storage has the value for demo mode
            let demoModeValue = window.localStorage.getItem("demo_mode");

            // Check if the value is present in session storage and is "true"
            if (demoModeValue && demoModeValue === "true") {
                return {
                    isDemoMode: true
                };
            } else {
                return state;
            }
    }
};